

import { Container, Row, Col, Button } from 'react-bootstrap';


import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../store/actions/users';
import { useEffect, useRef, useState } from 'react'
import LayoutCard from '../../hoc/layoutCard';
import { folderSearch, resetSearch } from '../../store/actions/search';
import 'react-datepicker/dist/react-datepicker.css'
import { Loader } from '../../utils/tools';
import { Link } from 'react-router-dom';
import { itIT } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import SimpleSearchIcon from '@mui/icons-material/Search';
import AdvancedSearchIcon from '@mui/icons-material/ScreenSearchDesktop';
import { globalNotification } from '../../store/reducers/notifications';
import { storeUserCookie } from '../../utils/tools';
import { formatDate } from '../../utils/tools';
import {Tooltip}  from '@mui/material';
import { getUserCookie } from '../../utils/tools';
const FolderSearch = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const q = queryParams.get("q")
    const [selectedRows, setSelectedRows] = useState([]);

    const navigate=useNavigate()

    const inputName = useRef(null)
 

    //const [page, setPage] = useState(0)
    //const [currentAuthor, setCurrentAuthor] = useState(0)
    const [currentSearch, setCurrentSearch] = useState({
        name: q
    })

    const showFolder = (params) => {
        const element = params.row
        window.open("/folder/" + element.id, "folder_" + params.id)

    }


    const [isLoading, setIsLoading] = useState(false)
    //const searchResults = useSelector(state=>state.search).results
    const items = useSelector(state=>state.search).results

    const [filteredItems, setFilteredItems] = useState([])


    const dispatch = useDispatch()

    useEffect(()=>{
        inputName.current.value = q
        dispatch(resetSearch())

    },[])
    useEffect(()=>{
        setFilteredItems(items)
    },[items])



    //reset page count on data grid
    const [paginationModel, setPaginationModel] = useState(getUserCookie('grid-pagination') ? {...getUserCookie('grid-pagination'), page: 0} : {
        pageSize: 50,
        page: 0,
    });
    
    const refreshResults = () => {
        setIsLoading(true)
        let currentParams = {
            name: inputName.current.value,
        }
        if(inputName.current.value == "") {
            dispatch(globalNotification({
                status: "error",
                message: "insert_one_search_param"
              }))
            return;
        }
        dispatch(folderSearch({data: currentParams, page: 0})).then(() => {
            setCurrentSearch(currentParams)
            setIsLoading(false)
        })
        


    }

    useEffect(() => {
        function handleKeyDown(e) {
            if(e.keyCode == 13 && !isLoading) {
                refreshResults()
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(()=>{
        setIsLoading(true)
        inputName.current.value = q
        if(inputName.current.value != "") {

            dispatch(folderSearch({data: currentSearch, page: 0})).then(() =>{
                setIsLoading(false)

            })
        }
        else {
            setIsLoading(false)

        }

        
    },[q])


    const inputFilter = useRef(null)

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        //console.log(keyword)
        setFilteredItems(items.filter(function (el) {
            //console.log(el)
            return el.title.toLowerCase().includes(keyword);
        }))
    }

    const columns = [
       
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: 400,
            flex:5,
            renderCell: (param) => {
                return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
        },
        { 
            field: 'path',
            headerName: 'Path',
            minWidth: 400,
            flex:10,
            renderCell: (param) => {
                return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
            
        },
        // {
        //     field: 'last_update', headerName: 'Data', width: 200,
        //     renderCell: (param) => {
        //         const formattedDate = formatDate(param.value)
        //         return <Tooltip title={formattedDate}><span>{formattedDate}</span></Tooltip>
        //     }

        // }
    ];

    const [sortModel, setSortModel] = useState(getUserCookie('grid-sort') &&
    getUserCookie('grid-sort').length > 0 &&
    columns.some(x => x.field == getUserCookie('grid-sort')[0]["field"]) ? getUserCookie('grid-sort') : [{
        field: 'path',
        sort: 'desc'
    }]);

  return(
    <>
       <LayoutCard
            title={"CERCA CARTELLE"}
        >
            <Container>
                <Row>
                    <Col md={12}>
                        <b>Nome</b><br></br>
                        <input className="search-input" ref={inputName} type="text" placeholder="Nome cartella" /><br></br>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{span: 3, offset: 9 }}>
                        <Button className="grey-button btn-block" onClick={refreshResults}>Cerca</Button>
                    </Col>
                </Row>
                
                </Container>
        </LayoutCard>
        <Container>
            <Row>
                    <Col sm={{span: 3, offset: 6}}>
                        <Button className="green-button btn-block" onClick={() => {navigate("/search")}}>
                            <SimpleSearchIcon/> Ricerca base
                        </Button>

                    </Col>
                    <Col sm={{span: 3 }}>
                        <Button className="ui-button btn-block" onClick={() => {navigate("/advanced-search")}}>
                            <AdvancedSearchIcon/> Ricerca avanzata
                        </Button>

                    </Col>
                    
                </Row>
                
        </Container>
        <LayoutCard
            title={"RISULTATI"}
            variant="white"
        >
            
            {
                isLoading ? 
                <Loader />
                :
                <>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                    <div style={{ height: 650, width: '100%' }}>
                        <DataGridPro
                            sortModel= {sortModel}
                            onSortModelChange={(model) => {
                                //console.log(model)
                                setSortModel(model)
                                storeUserCookie('grid-sort',model)
                            }}
                            paginationModel={paginationModel}
                            onPaginationModelChange={(param) => {
                                setPaginationModel(param)
                                storeUserCookie('grid-pagination', param)

                            }}
                            disableRowSelectionOnClick
                            
                            pagination={true}
                            pageSizeOptions={[50,100,200,300]}
                            rowsPerPageOptions={[50, 100, 200,300]}
                            
                            checkboxSelection={false}
                            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                            rows={filteredItems}
                            columns={columns}
                            onRowClick={showFolder}
                            
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRows(ids);
                            }}
                            getRowClassName={(params) =>
                                params.row.deleted ? 'deleted-row' : ''
                            }
                            isRowSelectable={(dataIndex, selectedRows) => {
                                return !dataIndex.row.deleted 
                            }}
                        />
                    </div>
                </>
                // <FoldersGrid
                //     items={searchResults}
                //     readOnly={true}
                //     loadMore={loadMore}
                // />
            }
            
        </LayoutCard>
       
    </>
  )

}


export default  FolderSearch