import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';


import { getGroups } from '../../store/actions/groups';
import GroupRow from '../elements/groupRow';
import { Loader } from '../../utils/tools';
const EditGroups = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        refreshTable()
    },[])
    const isLoadingGroups =useSelector(state=>state.groups).loading;
    const groups = useSelector(state=>state.groups).groups;

   const refreshTable = () => {
        dispatch(getGroups())

   }

    return(
        <>
            <h3>Gestione gruppi Intranet</h3>
            <Container className='admin-options'>
                <Row>
                    <Col xs={3}>
                        <b>Nome</b>        
                
                    </Col>
                    <Col xs={3}>
                        <b>Gruppo di default</b>
                    </Col>
                    <Col xs={3}>
                        <b>Gruppo di sistema</b>
                    </Col>

                    <Col xs={3}>

                    </Col>
                </Row>
            { 
                isLoadingGroups ?
                <Loader />
                :
                <>
                {
                    groups && groups.length > 0 ? 
                    groups.map((item,index)=>(
                        <GroupRow
                            item={item}
                            key={index}
                            onUpdate={refreshTable}
                        />
                    ))
                    :
                    ""
                }
                </>
                
            }
            <GroupRow
                onUpdate={refreshTable}
            />

            </Container>
            
        </>
    )
}

export default EditGroups;