
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import FolderIcon from '@mui/icons-material/Folder';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFolder } from '../../store/actions/folders';
import { useEffect } from 'react';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Tooltip}  from '@mui/material';


const FolderCard = ({folder, tooltip}) => {
  const {id, name, path, path_ids,parent_id, description} = folder
  //const [currentName, setCurrentName] = useState(name)
  const dispatch = useDispatch()
  // const updateCurrentFolder = () => {
  //   dispatch(updateFolder({folderId: id, name: currentName}))
  // }

  let profile = useSelector(state=>state.profile.data)

  // useEffect(()=>{
  //   setCurrentName(name)
  // }, [name])
    const tokenizedPath = path ? path.split("/").filter(e => e).reverse() : []
    const tokenizedPathIds = path_ids ? path_ids.split("/").filter(e => e).reverse() : []
    return(
            
      <Container className="grey-card">
        <Row>
          <Col lg={12} className='breadcrumb-container'>
            { tokenizedPath.length > 0 ?
              tokenizedPath.map((item,index) => {
                //if(tokenizedPathIds[index]  == profile.rootFolder) {
                if(index == tokenizedPath.length - 1)  {
                  return <div key={index}><Link className='breadcrumb-element right-link ui-icon bold-hover' to={"/"}>/ Home </Link></div>
                }
                else {
                  return <div key={index}><Link className='breadcrumb-element right-link ui-icon bold-hover' to={tokenizedPathIds[index] ? "/folder/" + tokenizedPathIds[index] : ""}>/ {item} </Link></div>

                }
              })

              :
              ""                    
            }
          </Col>
        </Row>
          
        <Row>
          <Col xs={12} lg={2} xl={1}>
            <Tooltip title={tooltip ?? name}>

            <FolderIcon sx={{ fontSize: 100 }}  />
            </Tooltip>
          </Col>
          <Col xs={12} lg={10} xl={11}>
            <div className='element-card-name-box'>
          
              <div>
                <h4><strong>{name}</strong></h4>

                <br />
                <p>{description}</p>
              </div>
            </div>
              
              

              
          </Col>
        </Row>
      </Container>

    )

}


export default FolderCard