import { Container, Row, Col } from 'react-bootstrap';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { IconButton, Tooltip } from '@mui/material';
import { VERSION } from '../../utils/costants';
import { Link } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
const Footer = () => {
 
    const goTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return(
        <>
           <nav className='footer-nav'>
                <Container>
                    <Row>
                        <Col xs={10}>
                        Intranet CISL - Versione {VERSION}
                        &nbsp;
                        <a target="_blank" href="https://webstorage.cisl.it/informatica/html/intranet/changelog/index.html" >
                            <Tooltip title="Changelog">
                                <ListAltIcon sx={{ fontSize: 20, color: "white" }} /> 

                            </Tooltip>
                        </a>
                        </Col>

                        <Col className='footer-up-icon'>
                            <IconButton aria-label="move" onClick={goTop} >
                                    <ArrowCircleUpIcon sx={{ fontSize: 40, color: "white" }} />
                            </IconButton>
                        
                        </Col>
                    </Row>
                    
                </Container>
               
                
                
              
            </nav>
        </>
    )
}

export default Footer;