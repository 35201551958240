import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add'
import UserAutocompleter from '../elements/autocompleter/userAutocompleter';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'react-bootstrap';
import { deleteTrash } from '../../store/actions/specialFolder';
import { globalNotification } from '../../store/reducers/notifications';
const EmptyTrash = () => {

    const [userId, setUserId] = useState(0)
    const [days, setDays] = useState(0)

    const showModalConfirm = () => {
        if(!userId) {
            dispatch(globalNotification({
                status: "error",
                message: "insert_valid_user"
            }))
            return
        }
        setModalState("modal-delete")
    }

    const [modalState, setModalState] = useState("close")


    const deleteUserTrash = () => {
        dispatch(deleteTrash({userId: userId, days: days}))

        handleClose()
    }

    const handleClose = () => {
        setModalState("close")
    }
 
   
    const dispatch = useDispatch();

    useEffect(()=>{
       

        
        
    }, [])


    return(
        <>
            <h3>Svuota cestino</h3>
            <Container className='admin-options'>
                <Row>
                    <Col xs={4}>
                        <b>Nome utente</b>
                
                    </Col>
                    <Col xs={4}>
                        <b>Elimina elementi più vecchi di (giorni):</b>
                    </Col>
                    <Col xs={4}>

                    </Col>
                </Row> 
          
                <Row className='menu-item'>
                    <Col xs={4}>
                        <UserAutocompleter setSelected={setUserId} />
                    </Col>
                    <Col xs={4}>
                        <input type="number" defaultValue={0} onChange={event => setDays(event.target.value)}></input><br></br>
                    </Col>
                    <Col xs={4}>
                        <DeleteIcon className='ui-icon' onClick={showModalConfirm} />
                    </Col>
                </Row>
                <Row className='menu-item'>
                    <Col xs={4}>
                    {/* * Lascia vuoto questo campo per svuotare i cestini di tutti gli utenti */}

                    </Col>
                    <Col xs={4}>
                    * Inserisci 0 se vuoi eliminare TUTTO il contenuto del cestino dell'utente

                    </Col>
                </Row>
            </Container>
            <Modal show={modalState === "modal-delete"} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Vuoi davvero eliminare il contenuto del cestino per l'utente selezionato?
                </Modal.Body>
                <Modal.Footer>
                <Button className='ui-button' onClick={deleteUserTrash}>Elimina</Button>
                </Modal.Footer>
            </Modal>

            
        </>
    )
}

export default EmptyTrash;