import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Container, Row, Col, Button } from 'react-bootstrap';
import LayoutCard from '../../hoc/layoutCard';
import { useEffect, useState } from 'react';
import { useDispatch} from 'react-redux';
import { getNotices } from '../../store/actions/specialFolder';
import PostPreview from '../elements/postPreview';
import { getCommonFolder } from '../../store/actions/specialFolder';
import {  Loader, getUserCookie } from '../../utils/tools';
import GenericIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AppIcon from '../elements/appIcon';
import EmailIcon from '@mui/icons-material/Email';
import RssViewer from '../elements/rssViewer';
import { Modal } from 'react-bootstrap';
import { htmlDecode } from '../../utils/tools';
import { getApplications } from '../../store/actions/profile';
import EmailViewer from '../elements/emailViewer';
import FoldersGrid from '../elements/grid/foldersGrid';
import { getHomeFolders, addFolder } from '../../store/actions/folders';
import { globalNotification } from '../../store/reducers/notifications';
import { VERSION } from '../../utils/costants';
const Home = () => {

    const iconMap = { EmailIcon }
    const dispatch = useDispatch();
    const noticeFolder = useSelector(state=>state.specialFolders.noticeFolder);
    let applications =  useSelector(state=>state.profile.data.applications);
    
    console.log("Intranet frontend v" + VERSION + " " +process.env.NODE_ENV)


    const profile = useSelector(state=>state.profile);

    const homeFolders =  useSelector(state=>state.folders).homeFolders;
    const isLoadingHomeFolders = useSelector(state=>state.folders.loadingHomeFolders);

    const [subFolderName, setSubfolderName] = useState("")
    const [subFolderDescription, setSubfolderDescription] = useState("")
    const addSubfolder = () => {
        if(subFolderName == "") {
            dispatch(globalNotification({
                status: "error",
                message: "insert_folder_name"
            }))
            return
          }
        dispatch(addFolder({name: subFolderName, parentId: profile.data.rootFolder, description: subFolderDescription})).then(() => {
            dispatch(getHomeFolders())

        })
        handleClose()
    }
 
    const noticeFolderTable = []
    
    // for(let i=0, r = 0;i < noticeFolder.length && r < 5; i++) {    
        // if(noticeFolder[i].type == 'post') {
    if(noticeFolder && noticeFolder.length > 0) {
        for(let i=0;i < noticeFolder.length; i++) {    

            noticeFolderTable.push(<PostPreview key={i} post={noticeFolder[i]} variant={i % 2 ? 'grey' : 'white'} />)
            // r++
        // }
        }
    }

    

    const [appList, setAppList] =useState(null)
    const commonFolder = useSelector(state=>state.specialFolders.commonFolder);
    //console.log(homeFolders,commonFolder.subfolders)

    const handleShowModalNewSubfolder = () => {
        setModalState("modal-subfolder")
       }


    const [modalState, setModalState] = useState("close")

    // const unseenAlerts = useSelector(state=>state.specialFolders.unseenAlerts);
    // const loadingAlerts = useSelector(state=>state.specialFolders.loading);


    //const isLoadingMyFolders = useSelector(state=>state.folders.loading);
    const isLoadingCommonFolders = useSelector(state=>state.specialFolders.loading);

    const alerts = useSelector(state=>state.specialFolders.alertsFolder);


    useEffect(()=>{
        dispatch(getHomeFolders())
        dispatch(getCommonFolder())
        dispatch(getNotices({
            offset: 0,
            limit: 5
        }))
        //dispatch(getUnseenAlerts())
        dispatch(getApplications())
    }, [])




    const [firstAlert, setFirstAlert] = useState(null)

    useEffect(()=>{

            if(alerts && alerts.length > 0) {
                    for(let i=0; i < alerts.length; i++) {
                        if(alerts[i].unread) {
                            setFirstAlert(alerts[i])
                            handleShowModalNewAlerts()
                            break

                        }
                    }
                
            }
        
        
    }, [alerts])

    useEffect(()=>{
        if(!applications || applications.length <= 0) {
            applications = []
        }
        setAppList(applications.map((app,index)=>{
            if(app.image) {
                return <Col key={index} lg={3} md={4} xxl={2} xs={6}><AppIcon link={app.link} name={app.name} image={app.image} /></Col>
            }
            else {
                return <Col key={index} lg={3} md={4} xxl={2} xs={6}><AppIcon link={app.link} name={app.name} Icon={iconMap[app.icon] ?? GenericIcon} /></Col>
            }
        }))
        
    }, [applications])

    const navigate = useNavigate()
    const handleShowModalNewAlerts = () => {
        setModalState("modal-alert")
    }

    const showFirstAlert = () => {
        if(firstAlert) {
            navigate("/post/" + firstAlert.id)
        }

        setModalState("close")
    }


    const handleClose = () => {
        setModalState("close")
    }


    

    return(
        <>

            <Container>
                <Row>
                    <Col md={5}>
                        <RssViewer />
                        <LayoutCard title={'ULTIME CIRCOLARI'} variant="white">
                            {
                                isLoadingCommonFolders ? 
                                <Loader />
                                :
                                (
                                    noticeFolder && noticeFolder.length > 0 ? 
                                    <div className='notice-box'>
                                    {noticeFolderTable}
                                    </div>
                                    :
                                    "Non si dispone dei permessi necessari"
                                )

                            }
                        </LayoutCard>
                        
                    </Col>
                    <Col md={7}>
                        <LayoutCard title={'APPLICAZIONI'} variant="white">
                        <Container>
                            <Row>
                                {appList ?? ""}
                            </Row>
                        </Container>
                        </LayoutCard>
                        <br />
                        <br />
                        <LayoutCard title={'INBOX'} variant="white">
                            <EmailViewer />
                        </LayoutCard>
                        
                    </Col>
                </Row>
               
                <hr></hr>
                <Row>
                    <Col md={12}>
                        <LayoutCard title={'GENERALI'} variant="white">
                            {
                                isLoadingCommonFolders ?
                                <Loader />
                                :
                                <FoldersGrid
                                    items={commonFolder ? commonFolder.subfolders :  []}
                                    readOnly={true}

                                />
                            }
                            
                        </LayoutCard> 
                    
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={12}>
                    <LayoutCard title={'CARTELLE'} variant="white" actionButton={{
                    action: handleShowModalNewSubfolder,
                    text: "Nuova cartella"
                    }}>
                        {
                            isLoadingHomeFolders ?
                            <Loader />
                            :
                            <FoldersGrid
                                items={homeFolders}
                            />
                            
                        }
                        
                    </LayoutCard>
                    
                    </Col>
                </Row>
            </Container>
           
            <Modal show={modalState === "modal-alert"} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuovo avviso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { firstAlert ? htmlDecode(firstAlert.title) : ""}
                </Modal.Body>
                <Modal.Footer>
                    {
                        firstAlert ?
                        <Button className='ui-button' onClick={showFirstAlert}>Visualizza</Button>
                        :
                        ""
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={modalState === "modal-subfolder"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuova Cartella</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" className='input-subfolder' maxLength="200" placeholder="Nome" onChange={event => setSubfolderName(event.target.value)}  />
          <textarea className='input-editfolder' placeholder="Descrizione" onChange={event => setSubfolderDescription(event.target.value)} ></textarea>

        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={addSubfolder}>Aggiungi</Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}

export default Home;