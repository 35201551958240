import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';

import axios from 'axios'
import { getAuthHeader } from '../../utils/tools';



export const getUsers = createAsyncThunk(
    'users/getUsers',
    async(data,{dispatch,getState})=>{
        try{
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`,getAuthHeader());

            return result.data

        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)

