import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom'

import { Loader } from '../../utils/tools'
import { signIn } from '../../store/actions/profile'


const AccountLogin = () => {
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const token = search.get('code')
    const secret = search.get('state')
    const redirect_uri = window.location.pathname
    useEffect(()=>{
        if(token){

            dispatch(signIn({token, secret, redirect_uri}))
            .unwrap()
            .finally(()=>{
                navigate(window.location.pathname)
            })
        } else{
            navigate(window.location.pathname)
        }
    },[dispatch,navigate])

    return(
        <>
           <Loader/>
        </>
    )
}

export default AccountLogin;

