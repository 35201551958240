import { createSlice } from '@reduxjs/toolkit';



export const notificationsSlice = createSlice({
    name:'notifications',
    initialState:{
       global:{}
    },
    reducers:{
        globalNotification:(state,action)=>{
            const status = action.payload.status ?? 'success'
            const message = action.payload.message ?? ''
            state.global.status = status;
            state.global.msg = message
        },
        clearNotifications:(state)=>{
            state.global = {}
        }
    }
})

export const {clearNotifications, globalNotification} = notificationsSlice.actions;
export default notificationsSlice.reducer;
