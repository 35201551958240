
import { Link } from 'react-router-dom';
import { Container, Row, Col, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux'

import AccountAvatar from '../../elements/accountAvatar';
const ProfileDropdown = ({onlyMobile = false}) => {
    const { fullname, admin } = useSelector(state=>state.profile.data)


    let buttonClassname = 'dropdown-profile-button btn'
    if(admin) {
        buttonClassname += ' btn-success'
    }
    else {
        buttonClassname += ' btn-secondary'

    }
    const title = (<div className={buttonClassname}><AccountAvatar /> {fullname}</div>)
    const navClassname = onlyMobile ?  'd-block d-lg-none' : 'profile-dropdown'
    return(
        <NavDropdown title={title}  className={navClassname}>
            <NavDropdown.Item eventKey={"profile"} as={Link} to="/profile">Profilo</NavDropdown.Item>
            {
                admin ?
                <NavDropdown.Item eventKey={"admin-settings"} as={Link} to="/admin/settings">
                Amministrazione
                </NavDropdown.Item>
                : 
                ""
            }
            <NavDropdown.Item eventKey={"settings"} as={Link} to="/settings">Impostazioni</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey={"change-account"} as={Link} to="/change-account">Cambia utente</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/logout">Esci</NavDropdown.Item>
        </NavDropdown>
    )

}

export default ProfileDropdown