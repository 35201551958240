import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader } from '../../utils/tools';
import axios from 'axios'
import { useSelector } from 'react-redux'
import {DEFAULT_LIMIT} from '../../utils/costants'
import createPalette from '@mui/material/styles/createPalette';


axios.defaults.headers.post['Content-Type'] = 'application/json';


export const advancedSearch = createAsyncThunk(
    'search/advanced',
    async({data, page = 0}, { dispatch })=>{
        try{
            //const limit = DEFAULT_LIMIT
            //const offset = page * limit
            
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/search?`
            for (const key in data) {
                if(data[key]) {
                    url += key + "=" + data[key] + "&"
                }
            }
            url = url.slice(0,-1)

            //url += "&offset=" + offset
            //url += "&limit=" + limit

            
            const result = await axios.get(url,getAuthHeader());
            
            return {
                    page: page,
                    searchResults: result.data
            }


        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)



export const simpleSearch = createAsyncThunk(
    'search/simple',
    async({data, page = 0}, { dispatch })=>{
        try{
            //const limit = DEFAULT_LIMIT
            //const offset = page * limit
            
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/filter?`
            for (const key in data) {
                if(data[key]) {
                    url += key + "=" + data[key] + "&"
                }
            }
            url = url.slice(0,-1)

            //url += "&offset=" + offset
            //url += "&limit=" + limit

            const result = await axios.get(url,getAuthHeader());
            
            return {
                    page: page,
                    searchResults: result.data
            }


        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const folderSearch = createAsyncThunk(
    'search/folder',
    async({data, page = 0}, { dispatch })=>{
        try{
            //const limit = DEFAULT_LIMIT
            //const offset = page * limit
            
            let url = `${process.env.REACT_APP_BACKEND_URL}/api/folder-search?`
            for (const key in data) {
                if(data[key]) {
                    url += key + "=" + data[key] + "&"
                }
            }
            url = url.slice(0,-1)

            //url += "offset=" + offset
            //url += "&limit=" + limit

            const result = await axios.get(url,getAuthHeader());
            
            return {
                    page: page,
                    searchResults: result.data
            }


        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)



export const resetSearch = createAsyncThunk(
    'search/reset',
    async(data, { dispatch })=>{
        //nothing to do
    }
)