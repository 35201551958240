import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css'

import { Provider } from 'react-redux'
import { store } from './store';
import { CookiesProvider } from "react-cookie";
import axios from 'axios'
import { removeTokenCookie } from './utils/tools';
import { Navigate } from 'react-router-dom';

axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
    //alert("logout")
    removeTokenCookie()
    window.location.href = '/logout'
    return error
   }
   throw error;
 });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <Provider store={store}>
            <Routes />
        </Provider>
    </CookiesProvider>
);

