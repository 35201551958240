import { useState } from "react";
import { useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getAuthHeader } from "../../../utils/tools";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  itIT,
);
const LdapGroupAutocompleter = ({setSelected}) => {
    const [options, setOptions] = useState([]);
    const previousController = useRef();

    const getData = (searchTerm) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/groups/ldap?q=` + searchTerm, {
            signal,
            headers: getAuthHeader().headers
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            const updatedOptions = myJson.map((g) => {
                return {id: g, title: g };

            });
            setOptions(updatedOptions);
        });
    };

    const isOptionEqualToValue = (p,q) => {
        return p.title == q.title
    }

    const onInputChange = (event, value, reason) => {
        if (value) {
            getData(value);
        } else {
            setOptions([]);
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
            options={options}
            onInputChange={onInputChange}
            onChange={(event, option) => setSelected(option ? option.id : 0 )} // prints the selected value

            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
            <TextField {...params} label="Seleziona un gruppo" variant="outlined" />
            )}
        />
    </ThemeProvider>
        
    )

}


export default  LdapGroupAutocompleter