import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';

import axios from 'axios'
import { getAuthHeader } from '../../utils/tools';


export const updateGroup = createAsyncThunk(
    'groups/updateGroup',
    async({groupId, data},{ dispatch })=>{
        try{

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/groups/${groupId}`,data, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return data
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const deleteGroup = createAsyncThunk(
    'groups/deleteGroup',
    async({groupId = 0},{ dispatch })=>{
        try{
         
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/groups/${groupId}`,getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return response.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const addGroup = createAsyncThunk(
    'groups/addGroup',
    async({name, is_default},{ dispatch })=>{
        try{
            const data = {
                name: name,
                is_default: is_default
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/groups`,data, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return {
                name: name,
                id: response.data.id,
                system: name,
                is_default: is_default
            };
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const addMapping = createAsyncThunk(
    'groups/addMapping',
    async(data,{dispatch})=>{
        try{
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/mapping`,data,getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))


            return response.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }

)


export const getParentGroups = createAsyncThunk(
    'groups/getParentGroups',
    async(data,{dispatch})=>{
        try{
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/parent`,getAuthHeader());

            return result.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }

)

export const deleteMapping = createAsyncThunk(
    'groups/deleteMapping',
    async({id},{dispatch})=>{
        try{
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/mapping/${id}`,getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return response.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }

)

export const getMapping = createAsyncThunk(
    'groups/getMapping',
    async(data,{dispatch})=>{
        try{
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/mapping`,getAuthHeader());
            return result.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)


export const getGroups = createAsyncThunk(
    'groups/getGroups',
    async(data,{dispatch})=>{
        try{
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups`,getAuthHeader());
            return result.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)

export const getLDAPGroups = createAsyncThunk(
    'groups/getLDAPGroups',
    async(data,{dispatch})=>{
        try{
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/ldap`,getAuthHeader());
            return result.data

        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)

