import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader, getTokenCookie, removeTokenCookie } from '../../utils/tools';
import { setAuthenticated } from '../reducers/profile';

import axios from 'axios'


export const signIn = createAsyncThunk(
    'profile/signIn',
    async(data,{dispatch,getState})=>{
        try{
            const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`,{
                code: data.token,
                redirect_uri: data.redirect_uri
            });
            
            dispatch(setAuthenticated(result.data))
            

        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)


export const getProfileData = createAsyncThunk(
    'profile/getProfileData',
    async(data,{dispatch,getState})=>{
        try{

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile`,getAuthHeader());
            result.data.app_token = getTokenCookie()
            dispatch(setAuthenticated(result.data))
            

        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }

)


export const getApplications = createAsyncThunk(
    'profile/getApplications',
    async(data,{dispatch,getState})=>{
        try{

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile/applications`,getAuthHeader());
            return result.data
            

        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }

)

export const signOut = createAsyncThunk(
    'profile/signOut',
    async()=>{
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`,[],getAuthHeader());
        removeTokenCookie();
    }
)

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async(data,{dispatch})=>{
        try{

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile`,data,getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
           
            return data
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)


export const updateProfileAvatar = createAsyncThunk(
    'profile/updateProfileAvatar',
    async(file,{dispatch})=>{
        try{
            const formData = new FormData(); 
     
            // Update the formData object 
            formData.append( 
                "avatar", 
                file, 
                file.name 
            );
     
            const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/avatar`,formData,getAuthHeader())
            const ret = {
                avatar: result.data['details']['path']
            }
            return ret
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

