import { Link } from "react-router-dom"
import AttachmentIcon from '@mui/icons-material/AttachFile';

import { Col, Container, Row } from "react-bootstrap"
import { formatDateEmail } from "../../utils/tools";
const MAX_CHAR_PREVIEW = 100
const EmailPreview = ({email, variant}) => {
    const content = email.title.length > MAX_CHAR_PREVIEW ? email.title.substring(0,MAX_CHAR_PREVIEW) + "..." : email.title
    return(
        <div className={variant == 'grey' ? 'grey-preview email-preview' : 'white-preview email-preview'}>
            <a href={email.link} target="_blank">
                <Container className="no-padding">
                    <Row>
                        <Col xs={3}>
                            {email.unread ? <div className="red-dot" title="Da leggere"></div> : <div className="hidden-dot" title="Letto"></div>}
                            {email.hasAttachment ? <AttachmentIcon sx={{ fontSize: 20 }}  /> : ""}
                            <span className="email-from">{email.from}</span>
                        </Col>
                        <Col xs={7} className="email-preview-content">
                            {content}
                        </Col>
                        <Col xs={2}>
                            {formatDateEmail(email.date)}
                        </Col>
                    </Row>
                </Container>
            </a>           
                
             
        </div>
    )

}


export default  EmailPreview