import FolderIcon from '@mui/icons-material/Folder';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSharedByMeIcon from '@mui/icons-material/DriveFileMove';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown'
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { getTokenCookie } from '../../../utils/tools';
import MoveIcon from '@mui/icons-material/MoveDown'
import { useDispatch } from 'react-redux/es/exports';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Tooltip } from '@mui/material';

const FolderBox = ({
    item,
    handleDelete,
    handleEdit,
    handleMove,
    index,
    handleClick,
    handleRestore,
    readOnly}) => {

    let icon;
    let href = !item.deleted ? "/folder/" + item.id : ""


    

   let clickAction

    if(handleClick) {
        clickAction = handleClick
    }


    const fontSize = 52


    
    
    if(item.shared) {
        if(item.mine) {
            //shared by me
            icon = <FolderSharedByMeIcon sx={{ fontSize: fontSize }}  />

        }
        else {
            //shared with me
            icon = <FolderSharedIcon sx={{ fontSize: fontSize }}  />
        }
    }
    else {
        icon = <FolderIcon sx={{ fontSize: fontSize }}  />
    }

    const operationsButtons= []
    if(!readOnly) {
        if(!item.deleted) {
            if(handleEdit) {
                operationsButtons.push(<a key="op_edit" onClick={(e) => handleEdit(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: 'folder',
                    name: item.name,
                    description: item.description
                })}><Tooltip title={"Modifica"}><EditIcon sx={{ fontSize: 20 }} /></Tooltip></a>)
            }
            if(handleMove) {
                operationsButtons.push(<a key="op_move" onClick={(e) => handleMove(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: 'folder',
                })}><Tooltip title={"Sposta"}><MoveIcon sx={{ fontSize: 20 }} /></Tooltip> </a>)
            }
            if(handleDelete) {
                operationsButtons.push(<a key="op_delete" onClick={(e) => handleDelete(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: 'folder'
                })}><Tooltip title={"Elimina"}><DeleteIcon sx={{ fontSize: 20 }} /></Tooltip> </a>)
            }
            
           
        
            if(handleClick) {
                href = "";
            }
        }
        else {
            if(handleRestore) {
                operationsButtons.push(<a key="op_restore" onClick={(e) => handleRestore(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: 'folder',
                    //path: item && item.path ? item.path + "/" + item.name : "",
                })}><RestoreFromTrashIcon sx={{ fontSize: 20 }} /> </a>)
            }
        }
    }

    const boxClassname = item.deleted ? "folder-card highlight-element dropdown-container background-grey" : "folder-card highlight-element dropdown-container"
    const iconClassname = item.deleted ? "box-content deleted-element" : "box-content"
    return(
        <div className={boxClassname}>
            <Tooltip title={item.popupTitle}>
                <Link to={href} onClick={clickAction}>
                    <div className={iconClassname}>
                        {icon}
                    </div>
                    <strong className='box-title'>{item.name}</strong>
                </Link>
            </Tooltip>
            
            <div className="dropdown-content">
                {operationsButtons}                    
            </div>
        </div>

        
    )
    

    

}


export default  FolderBox