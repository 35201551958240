
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom'

import { signOut } from '../../store/actions/profile'
import { Loader } from '../../utils/tools';


const Logout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(signOut())
        .unwrap()
        .finally(()=>{
            navigate('/')
        })
    })

    return(
        <>
            <Loader />
        </>
    )
}

export default Logout