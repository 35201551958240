import { Container, Row, Col, Button } from 'react-bootstrap';
import { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import AttachmentBox from '../box/attachmentBox';
const AttachmentsGrid = ({
    items,
    handleDeleteElement,
    handleClickElement,
}) => {

    const [filteredItems, setFilteredItems] = useState(items)
    const inputFilter = useRef(null)
    const navigate = useNavigate()
    
    
    useEffect(()=>{
        setFilteredItems(items)
    },[items])
  

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {

            return el.title.toLowerCase().includes(keyword);
        }))
    }
    return(
        <>
            
            <Container>
                <Row>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                </Row>
            </Container>
            
            
            <Container className="folder-container">
            { filteredItems && filteredItems.length > 0 ?
                <Row>
                { filteredItems.map((item,index)=>{

                        return(
                            <Col md={3} sm={6} lg={2} key={index}>
                                <AttachmentBox
                                    index={index}
                                    item={item}
                                    handleDelete={handleDeleteElement}
                                    handleClick={handleClickElement}
                                />
                            </Col>
                        )
                })}
                </Row>
                :
            <div><p>Nessun elemento da visualizzare</p></div>
        }
        
            
            </Container>
        </>
    )

}

export default AttachmentsGrid