import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from 'react-router-dom'
import AccountLogin from '../components/auth/verification';
import ExtLogin from '../components/auth/extLogin';
import {getTokenCookie} from '../utils/tools'
import { useDispatch } from "react-redux";
import { getProfileData } from "../store/actions/profile";
import { isProduction } from "../utils/tools";
const AuthGuard = (props) => {

    const profile = useSelector(state=>state.profile);
    const [searchParams] = useSearchParams();
    //console.log("CURRENT USER", user, getTokenCookie())
    // if(!isProduction()) {
    //     alert("DEV!")
    // }
    // else {
    //     alert("PROD!")
    // }
    const dispatch = useDispatch()
    
    const code = searchParams.get("code")
    if(code) {
        return <AccountLogin />
    }
    else if(profile.auth){
        return props.children
    }
    else {
        //get auth token from cookie
        const token = getTokenCookie()
        if(token) {
            dispatch(getProfileData())
        }
        else {
            return <ExtLogin callbackUrl={window.location.href} />
        }
       
        
    
    }

    
}

export default AuthGuard;