import { Routes,Route ,BrowserRouter } from 'react-router-dom';



import MainLayout from './hoc/mainLayout';
import Header from './components/navigation/header';
import Footer from './components/navigation/footer';
import Logout from './components/logout';

import Home from './components/home';
import Post from './components/posts/viewPost';

import Folder from './components/folders';

import UserProfile from './components/profile';
import NewPost from './components/posts/newPost'

import AuthGuard from './hoc/authGuard';
import EditPost from './components/posts/editPost';
import DownloadAttachment from './components/posts/downloadAttachment';
import Search from './components/search';
// import Notices from './components/notices';
import Trash from './components/trash';
//import Forum from './components/forum';
import AdvancedSearch from './components/search/advanced';
import Settings from './components/settings';
import ExtLogin from './components/auth/extLogin';
import AdminSettings from './components/settings/admin';
import FolderSearch from './components/search/folder';



const Router = () => {





  return(
      <BrowserRouter>
      
        <>
          <Header/>
          <MainLayout>
            <Routes>
                <Route path='/settings' element={ <AuthGuard><Settings /></AuthGuard>}/>
                <Route path='/admin/settings' element={ <AuthGuard><AdminSettings /></AuthGuard>}/>

                <Route path='/post/new' element={ <AuthGuard><NewPost /></AuthGuard>}/>
                <Route path='/post/:id' element={ <AuthGuard><Post /></AuthGuard>}/>
                <Route path='/post/:id/attachment' element={ <AuthGuard><DownloadAttachment /></AuthGuard>}/>

                <Route path='/post/:id/attachment/:attId' element={ <AuthGuard><DownloadAttachment /></AuthGuard>}/>

                <Route path='/post/:id/edit' element={ <AuthGuard><EditPost /></AuthGuard>}/>

                <Route path='/folder/:id' element={ <AuthGuard><Folder /></AuthGuard>}/>
                <Route path='/folder/:id/attachments' element={ <AuthGuard><DownloadAttachment /></AuthGuard>}/>

                <Route path='/folder/:id/new-post' element={ <AuthGuard><NewPost /></AuthGuard>}/>
                <Route path='/search' element={ <AuthGuard><Search /></AuthGuard>}/>
                <Route path='/advanced-search' element={ <AuthGuard><AdvancedSearch /></AuthGuard>}/>
                <Route path='/folder-search' element={ <AuthGuard><FolderSearch /></AuthGuard>}/>

                {/* <Route path='/notices' element={ <AuthGuard><Notices /></AuthGuard>}/> */}
                {/* <Route path='/forum' element={ <AuthGuard><Forum /></AuthGuard>}/> */}
                <Route path='/trash' element={ <AuthGuard><Trash /></AuthGuard>}/>

                <Route path='/profile' element={ <AuthGuard><UserProfile /></AuthGuard>}/>
                <Route path='/logout' element={<Logout/>} />

                <Route path='/change-account' element={<ExtLogin changeAccount={true} />} />

                <Route path='*' element={<AuthGuard><Home/></AuthGuard>}/>

              
            </Routes>
          </MainLayout>
          <Footer />
        </>
        
      </BrowserRouter>
   
  )
}

export default Router;