import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { getTokenCookie } from '../../utils/tools';


const custom_upload_handler = (blobInfo, progress) => new Promise((resolve, reject) => {
    var xhr, formData;
    if(blobInfo.blob().size > 300 * 1024) {
        reject({
            message: 'L\'immagine non può superare i 300kb',
            remove: true
        });
        return
    }
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    //                    images_upload_url: `${process.env.REACT_APP_BACKEND_URL}/api/posts/inline-attachments`,

    xhr.open('POST', `${process.env.REACT_APP_BACKEND_URL}/api/posts/inline-attachments`)
  

  
    xhr.onload = function() {
      const response = JSON.parse(xhr.responseText);
      
      if (!response) {
        reject({
            message: 'Risposta non valida: ' + xhr.responseText,
            remove: true
        });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        reject({
            message: "Si è verificato un errore nel caricamento dell'immagine",
            remove: true
        });
        return;
      }
  
  
      
      resolve(`${process.env.REACT_APP_BACKEND_URL}/` + response.location);
    };
  
    xhr.setRequestHeader('Auth-Token', getTokenCookie())

    xhr.onerror = function () {
        reject({
            message: 'Si è verificato un errore nel caricamento dell\'immagine. Code: ' + xhr.status,
            remove: true
        });
        return
    };
  
    formData = new FormData();
    formData.append('attachment', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);
})


const HtmlEditor = ({value, initialValue, setEditorState, postId}) =>{
    const editorRef = useRef(null);
    const onEditorChange = () => {
        if (editorRef.current) {
            const HTMLdata = editorRef.current.getContent()
            setEditorState(HTMLdata)
        }
        

    };


    return(
        <div className='html-editor-container'>
            <Editor
                apiKey="s91lqgl30mu7vfklzcn2ur1iceq1dyca934s3vepkuni7wk9"
                onInit={(evt, editor) => {
                    editorRef.current = editor
                    // editor.setContent(value)
                }}
                value={value}
                initialValue={initialValue}
                
                init={{
                    //forced_root_block: 'div',
                    cleanup: true,
                    images_upload_handler: custom_upload_handler,
                    file_picker_types: 'image',
                    automatic_uploads: true,
                    statusbar: false,
                    branding: false,
                    height: 400,
                    language: 'it',
                    //newline_behavior: 'invert',
                    content_style: "p {margin: 0}",
                    toolbar_mode: 'sliding',
                    menubar: false,
                    plugins: 'link image lists table',
                    toolbar: 'undo redo | ' +
                    'bold italic underline strikethrough forecolor backcolor hr | ' +
                    'alignleft aligncenter alignright alignjustify ' +
                    'bullist numlist outdent indent | ' +
                    'table tabledelete |' +
                    'fontfamily fontsize blocks | ' +                     
                    'link removeformat ' +
                    'image' 



                }}
                
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}

                onEditorChange={onEditorChange}
            />

        </div>
    )


}

export default HtmlEditor;