import { Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getPostComments } from '../../store/actions/posts';
import CommentCard from './commentCard';
import { useState } from 'react';
import { useMemo } from 'react';
import { DEFAULT_LIMIT } from '../../utils/costants';
import { Loader } from '../../utils/tools';
const CommentsContainer = ({postId,rights}) => {

    const dispatch = useDispatch();
    const comments = useSelector(state=>state.posts.current.comments);
    // console.log(comments)
    const hasMoreComments = useSelector(state=>state.posts.current.hasMoreComments);
    const [currentPage, setCurrentPage] = useState(0)
    const isLoadingComments = useSelector(state=>state.posts.loadingComments);
    const loadMore = () => {
        dispatch(getPostComments({postId: postId, page: currentPage}))
        setCurrentPage(currentPage + 1)
    }

    useEffect(()=>{
      dispatch(getPostComments({postId: postId, page: 0}))
      setCurrentPage(currentPage + 1)
    }, [])

    return(
        <>
        {
            isLoadingComments ?
            <Loader />
            :
            <Container className="folder-container">
            <Row>
                <Col>
                    <CommentCard postId={postId} />

                </Col>
            </Row>
            { comments && comments.length > 0 ?
                <Row>
                    <Col>
                        { comments.map((item,index)=>(
                            <div key={index}>
                                <CommentCard postId={postId} comment={item} rights={rights} />
                                <div className='child-comments'>
                                    <CommentCard postId={postId} parentCommentId={item.id} />

                                    { 
                                        item.children && item.children.length > 0 ? 
                                            item.children.map((child,index)=>(
                                                <CommentCard key={"child_" + index} comment={child} rights={rights} />                
                                            ))
                                        :
                                            ""
                                    }
                                    
                                </div>
                            </div>
                            

                        ))}
                    </Col>
                </Row>
                :
                <>
                </>
        }

        {
            hasMoreComments ?
            //comments && comments.length > 0 && comments.length % DEFAULT_LIMIT === 0 ?

                <Button className='ui-button' onClick={loadMore}>
                    Carica altri commenti
                </Button>
            :
                ""

        }
        </Container>

        }
        </>
        
       
    )

}

export default CommentsContainer