import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';

const MainLayout = (props) => {
    //set datagrid license at top level layout
    LicenseInfo.setLicenseKey('1ee4a0acdfaabe055c041117cfa450adTz03NTMwNyxFPTE3MjcwOTMyMDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

    return(
        <Container className={`app_container mb-5`}>
            {props.children}
            <ToastContainer/>
        </Container>
    )
}

export default MainLayout