import EditMenu from "./editMenu";
import EditSignature from "./editSignature";
import { useSelector } from "react-redux";
const Settings = () => {

    return(
        
           <EditSignature />
    )
}

export default Settings;