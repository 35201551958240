import { Container, Row, Col } from "react-bootstrap";
import HtmlEditor from "../elements/htmlEditor";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../store/actions/profile";
import { useSelector } from "react-redux";
import { useRef } from "react";
const EditSignature = () => {
    const dispatch = useDispatch()
    const [content, setContent] = useState('')
    let profile = useSelector(state=>state.profile.data)

    const appendSignatureInput = useRef(null)
    const [editorBlur,setEditorBlur] = useState();

    useEffect(() => {
        setContent(profile.signature)

    },[profile])

    const handleEditorState = (state) => {
        setContent(state)
    }

    const handleEditorBlur = (blur) => {
        setEditorBlur(true)
    }

    const saveSignature = (state) => {
        const data = {
            'signature' : content,
            'append_signature' : appendSignatureInput.current.checked ? 1 : 0
          }
          dispatch(updateProfile(data))
    }

    return(
        <>
            <h3>Firma dei messaggi</h3>
            <Container>
                <Row>
                    <Col>
                    <HtmlEditor
                        setEditorState={(state)=>handleEditorState(state)}
                        initialValue={profile.signature}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                        <input id="append-signature-checkbox" defaultChecked={profile.appendSignature == 1} type="checkbox" ref={appendSignatureInput} />
                        <label htmlFor="append-signature-checkbox"> Appendi la firma alla creazione del messaggio</label><br></br>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col md={{span: 3, offset: 9 }}>
                        <Button className='ui-button btn-block' onClick={saveSignature}>
                            Salva
                        </Button>
                    </Col>
                </Row>
        </Container>

            
        </>
    )
}

export default EditSignature;