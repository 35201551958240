
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import AccountAvatar from './accountAvatar';
import { formatDate } from '../../utils/tools';
import { useSelector, useDispatch } from 'react-redux';
import { addPostComment,deletePostComment, editPostComment} from '../../store/actions/posts';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { useRef } from 'react';
import { useEffect } from 'react';
import CommentEditor from './commentEditor';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Tooltip } from '@mui/material';
const CommentCard = ({postId, comment = null, parentCommentId, rights}) => {

  const dispatch = useDispatch();

  const authorId = comment ? comment.author : 0
  const commentId = comment ? comment.id : 0
  //const inputContent = useRef(comment ? comment.content : "")
  const [content, setContent] = useState(comment ? comment.content : "")

  const author = useSelector(state=>state.users).users.find(x => x.id === authorId);
  let profile = useSelector(state=>state.profile.data)
  const originalContent = (comment ? comment.content : "")
  const [modalState, setModalState] = useState("close")
  // const [editMode, setEditMode] = useState(false)

  const handleEditorState = (state) => {
    setContent(state)
  }

  useEffect(() => {
    //inputContent.current.value = comment ? comment.content : ""
    setContent(comment ? comment.content : "")
  },[comment])


  // const handleSetEditMode = () => {
  //   setEditMode(true)

  //   // if(editMode) {
  //   //   dispatch(editPostComment({commentId: commentId, content: ""})).then(() => {
  //   //     setEditMode(false)
  //   //   })
  //   // }
  //   // else {
  //   //   setEditMode(true)
  //   // }
  // }
  const deleteCurrentComment = () => {
    dispatch(deletePostComment({postId: postId, commentId: commentId}))
    handleClose()
  }

  const handleShowModalDelete = () => {
    setModalState("modal-delete")
  }
  
  const handleClose = () => {
    setContent(originalContent)
    setModalState("close")

  }

  const handleShowModalEdit = () => {
    if(!comment) {
      setContent("")
    }
    setModalState("modal-edit")
  }

  const editComment = () => {
    setModalState("close")

    if(comment) {
      dispatch(editPostComment({commentId: commentId, content: content})).then(() => {
        //setEditMode(false)
      })
    }
    else {
      dispatch(addPostComment({postId: postId, commentId: parentCommentId, content: content, authorId: profile.id})).then(() => {
        //setEditMode(false)
        setContent("")

      })
    }
  }


  // const submitComment = (e) => {
  //   if(e.keyCode == 13 && e.shiftKey == false) {

  //     e.preventDefault()
  //     e.target.disabled = true
  //     if(editMode) {
  //       dispatch(editPostComment({commentId: commentId, content: e.target.value})).then(() => {
  //         setEditMode(false)
  //       })
  //     }
  //     else {
  //       dispatch(addPostComment({postId: postId, commentId: parentCommentId, content: e.target.value, authorId: profile.id})).then(() => {
  //         e.target.value = ""
  //         e.target.disabled = false
  //       })
  //     }
      
      
  //   }
  // }

  const commentType = comment ? "comment-box" : "new-comment-box"
  return(
    <>
      <Container>
        <Row>
          <Col sm={1}>
            <div className="comment-avatar" >
              <AccountAvatar user={author ?? null} />
            </div>
          </Col>
          <Col sm={11}>
          <div className={commentType} >
            <div className='comment-title'>
              {author ? (author.fullname) : ""}
              {
                comment && (profile.admin || authorId == profile.id || rights["moderation"]) ?
                <>
                  <span className='comment-button'><ClearIcon sx={{ fontSize: 26 }} onClick={handleShowModalDelete}/></span>
                  {
                    comment ?
                    <span className='comment-button'><EditIcon sx={{ fontSize: 23 }} onClick={handleShowModalEdit}/></span>
                    :
                    ""
                  }

                </>
                
                :
                ""
              }
              
            </div>
            <div className='comment-body'>
                {
                  comment ?
                  <div className="comment-div" dangerouslySetInnerHTML={{__html: content}}>
                  </div>
                  :
                  <textarea readOnly onClick={handleShowModalEdit} className="comment-textarea" placeholder='Scrivi qualcosa...'></textarea>

                }
                
            </div>
            <div className='comment-date'>
              {
                comment && comment.updated_by > 0 ?
                <EditIcon sx={{ fontSize: 14 }}  />
                :
                ""
              }
              &nbsp;
              {
                comment ?
                (
                  comment.updated_by > 0 ?
                  formatDate(comment.last_update)
                  :
                  formatDate(comment.published)
                )
                :
                ""
              }
              </div>
          </div>
          </Col>
        </Row>
      </Container>
      
      <Modal show={modalState === "modal-delete"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero eliminare il commento?
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={deleteCurrentComment}>Elimina</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalState === "modal-edit"} onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>{comment ? "Modifica" : "Inserisci"} commento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <CommentEditor
                value={content}
                setEditorState={(state)=>handleEditorState(state)}
              />
          </Modal.Body>
          <Modal.Footer>
            <Tooltip title={"Salva"}>
              <IconButton aria-label="move" onClick={editComment} >
                  <SaveIcon className='ui-icon' />
              </IconButton>
            </Tooltip>
            
          </Modal.Footer>
      </Modal>
    </>
    
  )

}


export default CommentCard