import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader } from '../../utils/tools';
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json';


export const getSettings = createAsyncThunk(
    'settings/getSettings',
    async(data, { dispatch })=>{
        try{
            
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/settings`,getAuthHeader());
            return result ? result.data : []


        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const updateSetting = createAsyncThunk(
    'settings/updateSetting',
    async({id,value},{ dispatch })=>{
        try{
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/settings/${id}`,{
                value: value
            }, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return response.data;


        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)
