

import AccountIcon from '@mui/icons-material/AccountCircle'
import { useSelector } from 'react-redux/es/exports'

const AccountAvatar = ({user = null,size = 25}) => {
    let {avatar} = useSelector(state=>state.profile.data)
    if(user) {
        avatar = user["avatar_path"] //override avatar with user avatar
    }

    const href = process.env.REACT_APP_BACKEND_URL + "/" + avatar
    return(
        <>
        {
            avatar ?
                <img style={{width: size + 'px', height: size +'px'}} className="account-avatar" src={href} />
            :
                <AccountIcon sx={{ fontSize: size }} />
        }
        </>
        
        
    )

}


export default AccountAvatar