import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "generic_success": "Operazione completata",
      "generic_error": "Si è verificato un errore",
      "invalid_oauth_authentication": "Invalid Oauth autentication",
    }
  },
  it: {
    translation: {
      "generic_success": "Operazione completata",
      "generic_error": "Si è verificato un errore",
      "generic_warning": "Attenzione! Si è verificato un errore",
      "folder_not_found": "La cartella selezionata non esiste",
      "update_user_success": "Dati aggiornati",
      "update_folder_success": "Cartella modificata correttamente",
      "create_folder_success": "Cartella creata correttamente",
      "folder_not_empty": "La cartella non è vuota",
      "max_file_size_exceeded": "Il file supera le dimensioni massime consentite",
      "invalid_oauth_authentication": "Si è verificato un errore di autenticazione",
      "post_not_found": "Il contenuto non è stato trovato",
      "unable_to_find_post_in_elastic": "Impossibile recuperare il contenuto del post",
      'read': "Lettura",
      'write': "Scrittura",
      'write_adv': "Scrittura avanzata",
      'write_comment': "Scrittura commenti",
      'moderation': "Moderazione",
      'permission_management': "Gestione permessi",
      'view_history': "Visualizza cronologia",
      'folder_already_exists': "La cartella contiene già una sottocartella con lo stesso nome",
      'create_folder_error': "Si è verificato un errore nella creazione della cartella",
      "add_rights_success": "Permesso assegnato",
      "delete_rights_success": "Permesso rimosso",
      "create_post_error": "Si è verificato un errore nella creazione del contenuto",
      "create_post_success": "Contenuto creato correttamente",
      "search_failed": "Si è verificato un errore nella ricerca",
      "update_folder_right_success": "Permesso aggiornato",
      "update_folder_right_error": "Si è verificato un errore nell'aggiornamento del permesso",
      "unable_to_update_post": "Impossibile aggiornare il contenuto",
      "read_folder_denied": "Non hai le autorizzazioni per accedere al contenuto",
      "invalid_admin_session": "Devi essere un amministratore per effettuare l'operazione",
      "moderation_post_denied": "Impossibile eliminare o spostare contenuti della cartella",
      "only_owner_can_update": "Solo il proprietario può modificare il contenuto del contenuto",
      "create_comment_error": "Impossibile commentare il post",
      "view_history_post_denied": "Impossibile visualizzare la cronologia delle visualizzazioni",
      "unable_to_move_folder": "Impossibile spostare la cartella nella destinazione indicata",
      "insert_title_and_content": "Inserisci il titolo ed il contenuto",
      "delete_post_error": "Si è verificato un errore nell'eliminazione del contenuto",
      "delete_post_success": "Contenuto eliminato correttamente",
      "update_post_success": "Contenuto aggiornato correttamente",
      "create_attachment_success": "Allegato caricato con successo",
      "create_attachment_error": "Si è verificato un errore nell'inserimento dell'allegato",
      "delete_attachment_error": "Si è verificato un errore nella rimozione dell'allegato",
      "attachment_not_found": "Allegato non trovato",
      "write_folder_denied": "Impossibile scrivere nella cartella indicata",
      "invalid_attachment_download": "Impossibile scaricare il file. Download non riuscito",
      "read_post_denied": "Non hai le autorizzazioni per accedere al contenuto",
      "comment_not_found": "Il commento non è stato trovato",
      "destination_folder_not_found": "La cartella di destinazione non è stata trovata",
      "create_menu_item_error": "Si è verificato un errore nella modifica del menù",
      "create_menu_item_success": "Menù aggiornato correttamente",
      "session_token_expired": "Token di sessione scaduto",
      "invalid_session_user": "L'utente loggato non è valido",
      "delete_folder_success": "Cartella eliminata correttamente",
      "delete_folder_denied": "Impossibile eliminare la cartella. Permesso negato",
      "update_folder_denied": "Impossibile modificare la cartella. Permesso negato",
      "update_folder_error": "Si è verificato un errore nella modifica della cartella",
      "create": "Inserimento",
      "update": "Modifica",
      "view": "Visualizzazione",
      "insert_folder_name": "Inserisci il nome della cartella",
      "delete_mapping_success": "Associazione rimossa con successo",
      "delete_mapping_error": "Impossibile rimuovere l'associazione",
      "mapping_not_found": "Associazione non trovata",
      "insert_title_and_link": "Inserisci il titolo ed il link",
      "create_group_success": "Creazione del gruppo avvenuta con successo",
      "create_group_error": "Si è verificato un errore nella creazione del gruppo",
      "create_mapping_success": "Associazione inserita",
      "create_mapping_error": "Si è verificato un errore nella creazione dell'associazione",
      "refresh_token_error": "Si è verificato un errore nel refresh del token",
      "unable_to_decode_jwt": "Impossibile decodificare il token JWT",
      "jwt_not_verified": "Impossibile verificare la firma del token JWT",
      "restore_post_error": "Impossibile ripristinare il post selezionato",
      "restore_post_success": "Ripristino del post avvenuto correttamente",
      "reader": "Lettore",
      "commentator": "Commentatore",
      "writer":"Scrittore",
      "adv_writer": "Scrittore avanzato",
      "moderator": "Moderatore",
      "handler": "Gestore",
      "disabled": "Disabilitato",
      "custom": "Personalizzato",
      "create_folder_right_success": "Permesso assegnato",
      "create_folder_right_error": "Si è verificato un errore nell'assegnazione del permesso",
      "invalid_right": "Permesso non valido",
      "delete_folder_right_success": "Permesso rimosso",
      "delete_folder_right_error": "Si è verificato un errore nella rimozione del permesso",
      "right_already_exists": "Il permesso è già stato assegnato",
      "handle_rights_denied": "Non puoi gestire i permessi della cartella",
      "destination_folder_to_move_denied": "Impossibile spostare la cartella nella cartella desiderata",
      "right_not_found": "Il permesso non è stato trovato",
      "delete": "Eliminazione",
      "restore": "Ripristino",
      "folder_not_in_your_trash": "La cartella selezionata non è presente nel tuo cestino. Potrebbe essere stata eliminata da un altro utente. Impossibile ripristinare.",
      "post_not_in_your_trash": "Il post selezionato non è presente nel tuo cestino. Potrebbe essere stato eliminato da un altro utente. Impossibile ripristinare.",
      "edit_post_denied": "Impossibile modificare il post. Permesso negato.",
      "max_attachment_size": "Dimensione massima file allegati (in byte)",
      "setting_not_found": "Impostazione non trovata",
      "update_setting_success": "Impostazione aggiornata",
      "update_setting_error": "Si è verificato un errore nella modifica dell'impostazione",
      "update_group_success": "Gruppo aggiornato con successo",
      "update_group_error": "Si è verificato un errore nell'aggiornamento del gruppo",
      "must_specify_only_one_parent_id": "Puoi inserire solo un gruppo contenitore per il gruppo destinazione indicato",
      "insert_attachment_name": "Inserisci il nome dell'allegato",
      "insert_attachment": "Inserisci l'allegato",
      "attachment_cannot_be_indexed": "L'allegato non può essere indicizzato per la ricerca",
      "folder_restored_successfully": 'Cartella ripristinata correttamente',
      "folder_moved_successfully": "Cartella spostata correttamente",
      "post_restored_successfully": 'Contenuto ripristinato correttamente',
      "post_moved_successfully": 'Contenuto spostato correttamente',
      "link_is_required": "Inserisci un link valido",
      "insert_link_name": "Inserisci il nome del link",
      "insert_one_search_param": "Inserisci almeno un parametro di ricerca",
      "update_post_error": "Impossibile modificare il contenuto",
      "get_folder_content_error": "Non hai le autorizzazioni per accedere al contenuto",
      "insert_valid_user": "Inserisci un utente valido",
      "delete_trash_error": "Impossibile eliminare il contenuto del cestino selezionato",
      "delete_trash_success": "Contenuto del cestino eliminato",
      "wait_for_load": "Caricamento dell'allegato in corso... Non chiudere la finestra fino alla fine del caricamento!",
      "downloading_file": "Download dell'allegato in corso... Non chiudere la finestra fino alla fine del caricamento!",
      "unable_to_create_post": "Impossibile creare il post",
      "move": "Spostamento",
      "unable_to_search": "Impossibile effettuare la ricerca",
      "no_files_selected": "Nessun file da scaricare"





      










    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;