import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getMenu } from '../../store/actions/menuItems';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import { editWholeMenu } from '../../store/actions/menuItems';
import { getLDAPGroups, getMapping, getGroups, addMapping, updateGroup, getParentGroups } from '../../store/actions/groups';
import LdapGroupAutocompleter from '../elements/autocompleter/ldapGroupAutocompleter';
import GroupAutocompleter from '../elements/autocompleter/groupAutocompleter';
import { Loader } from '../../utils/tools';
const EditParentGroups = () => {

    const [parentGroup, setParentGroup] = useState(0)

    const [group, setGroup] = useState(0)

    const parentGroups = useSelector(state => state.groups.parentGroups)

    const loading = useSelector(state => state.groups.loading)


    const addItem = (element) => {
        if(group > 0 && parentGroup > 0) {
            dispatch(updateGroup({
                groupId: group,
                data: {
                    parent_id: parentGroup
                }
            })).then(() => {
                dispatch(getParentGroups())
            })
          }
    }

 
    const removeItem = (index) => {
        const elem = parentGroups[index]
        dispatch(updateGroup({
            groupId: elem.child_id,
            data: {
                parent_id: null
            }
        })).then(() => {
            dispatch(getParentGroups())
        })
    }



    const dispatch = useDispatch();

    useEffect(()=>{
       
        dispatch(getGroups())
        dispatch(getParentGroups())

        
        
    }, [])


    return(
        <>
            <h3>Gruppi contenitore</h3>
            <Container className='admin-options'>
                <Row>
                    <Col xs={4}>
                        <b>Gruppo Intranet Contenitore</b>
                
                    </Col>
                    <Col xs={4}>
                        <b>Gruppo Intranet</b>
                    </Col>
                    <Col xs={4}>

                    </Col>
                </Row> 
            {
                loading ? 
                <Loader />
                :
                <>
                {
                    parentGroups && parentGroups.length > 0 ? 
                    parentGroups.map((item,index)=>(
                            <Row key={index}>
                                <Col xs={4}>
                                    {item.name}
                            
                                </Col>
                                <Col xs={4}>
                                    {item.child_name}
    
                                </Col>
                                <Col xs={4}>
                                    <DeleteIcon className='ui-icon' onClick={() => removeItem(index)} />
    
                                </Col>
                            </Row> 
                        ))
                    :
                        ""
                }
                </>
                
            }
            <Row className='menu-item'>
                <Col xs={4}>
                    <GroupAutocompleter setSelected={setParentGroup} />
                </Col>
                <Col xs={4}>
                    <GroupAutocompleter setSelected={setGroup} />
                </Col>
                <Col xs={4}>
                    <AddIcon className='ui-icon' onClick={addItem} />
                </Col>
            </Row>
            </Container>
            

            
        </>
    )
}

export default EditParentGroups;