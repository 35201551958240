import { configureStore } from '@reduxjs/toolkit';
import ProfileReducer from './reducers/profile';
import PostsReducer from './reducers/posts';
import UsersReducer from './reducers/users';
import GroupsReducer from './reducers/groups';
import SpecialFoldersReducer from './reducers/specialFolders'
import FoldersReducer from './reducers/folders';
import NotificationsReducer from './reducers/notifications';
import SearchReducer from './reducers/search';
import MenuReducer from './reducers/menuItem';
import SettingReducer from './reducers/settings';


export const store = configureStore({
    reducer:{
        profile: ProfileReducer,
        folders: FoldersReducer,
        notifications: NotificationsReducer,
        posts: PostsReducer,
        users: UsersReducer,
        groups: GroupsReducer,
        specialFolders: SpecialFoldersReducer,
        search: SearchReducer,
        menuItems: MenuReducer,
        settings: SettingReducer

    }
})