import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
const LayoutCard = (props) => {
    //console.log(props.actionButton)
    return(
        <div className='layout-card'>
            <Row>
            <Col xs={8} md={10}>

                <span className="green-rectangle" >&nbsp;</span><strong className="layout-card-title">{props.title}</strong>

            </Col>
                {
                props.actionButton ? 
                <Col xs={4} md={2}>
                    {props.actionButton.href ?
                    <Link  to={props.actionButton.href}>
                        <button className="btn btn-success action-button">{props.actionButton.text}</button> 
                    </Link>
                    :
                    <div>
                        <button className="btn btn-success action-button" onClick={props.actionButton.action}>{props.actionButton.text}</button> 
                    </div>
                    }
                    
                </Col>

                :
                ""
                }
            </Row>
            
            <br></br>
            <br></br>
            <div className={props.variant == "white" ? "white-card" : "grey-card"}>
                {props.children}
            </div>

      
        </div>
    )

}


export default LayoutCard