import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader } from '../../utils/tools';
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json';


export const getMenu = createAsyncThunk(
    'menuItems/getMenu',
    async(data, { dispatch })=>{
        try{
            
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/menu`,getAuthHeader());
            return result ? result.data.childs : []


        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
 
        }
    }
)

export const editWholeMenu = createAsyncThunk(
    'menuItems/editWholeMenu',
    async(menu, { dispatch })=>{
        try{
            
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/menu/whole`, {
                menu: menu
            }, getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return response ? response.data.childs : []


        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)
