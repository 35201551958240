import { createSlice } from '@reduxjs/toolkit';
import { simpleSearch, advancedSearch, resetSearch,folderSearch } from '../actions/search';


let DEFAULT_STATE = {
    loading:false,
    results: []
}

export const searchSlice = createSlice({
    name:'search',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder
        .addCase(advancedSearch.pending,(state)=>{ state.loading = true })
        .addCase(advancedSearch.fulfilled,(state,action)=>{
            state.loading = false;
            if(action.payload.page == 0) {
                state.results = action.payload.searchResults
            }
            else {
                state.results = [...state.results, ...action.payload.searchResults]
            }
        })
        .addCase(advancedSearch.rejected,(state)=>{ state.loading = false })

        .addCase(simpleSearch.pending,(state)=>{ state.loading = true })
        .addCase(simpleSearch.fulfilled,(state,action)=>{
            state.loading = false;
            if(action.payload.page == 0) {
                state.results = action.payload.searchResults
            }
            else {
                state.results = [...state.results, ...action.payload.searchResults]
            }
        })
        .addCase(simpleSearch.rejected,(state)=>{ state.loading = false })

        .addCase(folderSearch.pending,(state)=>{ state.loading = true })
        .addCase(folderSearch.fulfilled,(state,action)=>{
            state.loading = false;
            if(action.payload.page == 0) {
                state.results = action.payload.searchResults
            }
            else {
                state.results = [...state.results, ...action.payload.searchResults]
            }
        })
        .addCase(folderSearch.rejected,(state)=>{ state.loading = false })

        .addCase(resetSearch.pending,(state)=>{ state.loading = true })
        .addCase(resetSearch.fulfilled,(state,action)=>{
            state.loading = false;
            state.results = []
        })
        .addCase(resetSearch.rejected,(state)=>{ state.loading = false })
      
    }
})



export default searchSlice.reducer;
