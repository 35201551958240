import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { getSettings, updateSetting } from '../../store/actions/settings';
import SaveIcon from '@mui/icons-material/Save';
import i18n from '../../i18n/translations';
import { useTranslation } from 'react-i18next';

const EditSettings = () => {



    const { t } = useTranslation()

 
   
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings.settings)


    let newSettings = []
    useEffect(()=>{
       
        dispatch(getSettings())
        
    }, [])

    useEffect(()=>{
       
        if(settings) {
            for (let i = 0; i < settings.length; i++) {
                newSettings[i] = {};
                newSettings[i]["id"] = settings[i]["id"];

                newSettings[i]["name"] = settings[i]["name"];
                newSettings[i]["value"] = settings[i]["value"];
            }
        }
        
    }, [settings])
    const changeSetting = (e,index) => {
        newSettings[index].value = e.target.value
        //console.log(newSettings)
    }



    const saveSetting = (index) => {

        dispatch(updateSetting({
            id: newSettings[index].id,
            value: newSettings[index].value
        }))
    }


    return(
        <>
            <h3>Impostazioni generali</h3>
            <Container className='admin-options'>
               
            { 
                settings && settings.length > 0 ? 
                settings.map((item,index)=> {
                    return (
                        <Row key={index}>
                            <Col xs={4}>
                                {t(item.name)}
                        
                            </Col>
                            <Col xs={4}>
                                {
                                    item.locked ?
                                    item.value
                                    :
                                    <input type="text" defaultValue={item ? item.value : ""} onChange={(e) => changeSetting(e,index)}></input>

                                }
                            

                                

                            </Col>
                            <Col xs={4}>
                                {
                                    !item.locked ?
                                        <>
                                        <SaveIcon className='ui-icon' onClick={() => saveSetting(index)} />
                                        </>
                                        :
                                        ""
                                    
                                
                               

                                }
                                

                            </Col>
                        </Row> 
                    )
                })

                :
                    ""
            }
         
            </Container>
            

            
        </>
    )
}

export default EditSettings;