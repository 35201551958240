import { useEffect } from 'react';
import HeaderTop from './header/top';
import HeaderMain from './header/main';
import HeaderBottom from './header/bottom';

import { showToast } from '../../utils/tools';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotifications } from '../../store/reducers/notifications'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/translations';
const Header = () => {
    const notifications = useSelector(state => state.notifications);
    const dispatch = useDispatch();


    const { t } = useTranslation();

    useEffect(()=>{
        let { global } = notifications;
         if(notifications && global.status){
            const msg = global.msg ? t(global.msg) : "";
            showToast(global.status, msg)
            dispatch(clearNotifications())
        }
        // if(notifications && global.error){
        //     const msg = global.msg ? t(global.msg) : t('generic_error');
        //     showToast('ERROR',msg)
        //     dispatch(clearNotifications())
        // }
        // if(notifications && global.success){
        //     const msg = global.msg ? t(global.msg) : t('generic_success');
        //     showToast('SUCCESS',msg)
        //     dispatch(clearNotifications())
        // }
        // if(notifications && global.warning){
        //     const msg = global.msg ? t(global.msg) : t('generic_warning');
        //     showToast('WARNING',msg)
        //     dispatch(clearNotifications())
        // }
    },[notifications])



    return(
        <>

            <HeaderTop />
            <HeaderMain />
            <HeaderBottom />
        </>
    )
}

export default Header;