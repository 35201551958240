import {  useNavigate, useParams } from "react-router-dom";
import { Loader, downloadAttachment, getTokenCookie } from "../../utils/tools";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { WindowSharp } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/translations';
import { globalNotification } from "../../store/reducers/notifications";
import { useLocation } from "react-router-dom";
const DownloadAttachment = () => {
    const location = useLocation();
    const { state } = location;
    const { id, attId } = useParams()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const navigate = useNavigate()
    useEffect(() => {
        let url
        if(window.location.href.indexOf('folder') !== -1) {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/folders/${id}/attachments`

        }
        else {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments`
            if(attId) {
                url += `/${attId}`
            }

        }
        
        downloadAttachment(url,state ? state.ids : null).catch(error => {
            dispatch(globalNotification({
                status: "error",
                message: error
            }))
        })
        .finally(() => {
            if(window.history.state && window.history.state.idx > 0) {
                navigate(-1)
            }
            else {
                window.close()
            }
        })

        
    },[])
    
   
    return(
        <>
            <Loader />
            <div className="element-card-content">{t("downloading_file")}</div>
        </>
    )
}

export default DownloadAttachment