import TitleBar from '../elements/titlebar'
import { Container, Row, Col } from 'react-bootstrap';
import {Button} from 'react-bootstrap'
import { useState } from 'react';
import EyeIcon from '@mui/icons-material/RemoveRedEye'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {  useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { deletePost, getPost, getPostAttachments, getPostAuthor, getPostHistory, movePost, myPostRights } from '../../store/actions/posts';
import { getAllAccessibleFolders } from '../../store/actions/folders';
import { getUsers } from '../../store/actions/users';
import {  getTokenCookie, Loader } from '../../utils/tools';
import AccountAvatar from '../elements/accountAvatar';
import CommentsContainer from '../elements/commentsContainer';
import { formatDate } from '../../utils/tools';
import LayoutCard from '../../hoc/layoutCard';
import DeleteIcon from '@mui/icons-material/Delete'
import MoveIcon from '@mui/icons-material/MoveDown'
import { Dropdown } from 'react-bootstrap';
import SettingsIcon from '@mui/icons-material/Settings';
import { globalNotification } from '../../store/reducers/notifications';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/ModeEdit';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/translations';
import AccessDenied from '../elements/accessDenied';
import PrintIcon from '@mui/icons-material/Print';
import AttachmentsGrid from '../elements/grid/attachmentsGrid';
import FolderBox from '../elements/box/folderBox';

const Post = (props) => {


  const { id } = useParams();
  const navigate = useNavigate()
  const currentPost = useSelector(state=>state.posts.current);
  const isLoading = useSelector(state=>state.posts.loading);
  const myRights = useSelector(state=>state.posts.current.myRights);
  const [destinationFolder, setDestinationFolder] = useState(null);

  const users = useSelector(state=>state.users).users;
  const [modalState, setModalState] = useState("close")
  const profile = useSelector(state=>state.profile.data);

  const isLoadingAllFolders = useSelector(state=>state.folders.loadingAll);
  const allAccessibleFolders =  useSelector(state=>state.folders).all;

  const [allAccessibleFoldersFiltered, setAllAccessibleFoldersFiltered] = useState([])

  useEffect(()=>{
      setAllAccessibleFoldersFiltered(allAccessibleFolders)
  },[allAccessibleFolders])
  const { t } = useTranslation();

  
  const tokenizedPath = currentPost.path ? currentPost.path.split("/").filter(e => e).reverse() : []
  const tokenizedPathIds = currentPost.path_ids ? currentPost.path_ids.split("/").filter(e => e).reverse() : []

  const inputFolderFilter = useRef(null)
  // const handleDownloadAllAttachments = () => {
  //   if(attachmentList.length > 0 ) {
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments`
      
  //     downloadAttachment(url).catch(error => {
  //         dispatch(globalNotification({
  //             status: "error",
  //             message: error
  //         }))
  //     })
  //   }
    
  // }
  const printContent = () => {
    var content = document.getElementsByClassName("post-textarea")[0]
    var header="<div style=\"font-size: 20px;\"><b>Titolo:</b> " + currentPost.title + "<br />"
    header += "<b>Autore:</b> " + currentPost.author + "<br />"
    header += "<b>Data:</b> " + formatDate(currentPost.last_update ?? currentPost.published) + "<br />"
    header += "<b>Percorso:</b> " + currentPost.path + "<br />"
    if(attachmentList.length > 0) {
      header += "<b>Allegati:</b><br />"
      attachmentList.forEach((item) => {
        header += "<span>• " + item.title + "</span><br />"
      })
      header += "<br />"

    }
    header += "<b>Testo: </b>"

  
    header += "</div><br />"
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(header + content.innerHTML);
    pri.document.close();
    pri.focus();
    setTimeout(() => {
      pri.print();
    }, 1000);
  }



  const folderFilter = () => {
    const keyword = inputFolderFilter.current.value.toLowerCase()
      setAllAccessibleFoldersFiltered(allAccessibleFolders.filter(function (el) {

            return el.title.toLowerCase().includes(keyword);
        }))
  }

  const [deletingPost, setDeletingPost] = useState(false)

  const deleteCurrentPost = () => {
    setDeletingPost(true)
    dispatch(deletePost({id: id})).then(() => {
      navigate("/folder/" + currentPost.folder_id,{ replace: true })
    })
    handleClose()
  }

  
  const confirmMoveSelectedFolder = (destination) => {
    setDestinationFolder(destination)
    setModalState("modal-confirm-move")
 
  }

  const moveCurrentElement = () => {
    //alert("I want to move " + currentPost.id + " in " + folderId)
    dispatch(movePost({id: id, folderId: destinationFolder.id}))
    setDestinationFolder(null)
    handleClose()
  }

  const handleShowModalMove = () => {
    dispatch(getAllAccessibleFolders('write_adv'))
    setModalState("modal-move")
  }

  const handleEdit = () => {
    navigate("/post/"+id+"/edit")
  }


  


  const handleShowModalHistory = () => {
    dispatch(getPostHistory({id}))
    setModalState("modal-history")
  }
  const handleShowModalDelete = () => {
    setModalState("modal-delete")
  }
  const handleClose = () => {
    setModalState("close")
   }
  const dispatch = useDispatch();
  useEffect(()=>{
      dispatch(getPost({id})).then( (data) => {
   
        const postId = data.payload ? data.payload.id : null
        if(postId) {
          if(data.payload.type == 'attachment' || data.payload.type == 'link') {
            navigate(-1)
          }
          else {
            dispatch(getPostAttachments({id}))
            dispatch(getUsers())
            dispatch(myPostRights({id}))
            dispatch(getPostAuthor({id}))


          }
          
        }
        
    })
  },[id])

  let attachmentList = []
  currentPost.attachments.forEach((item) => {
    attachmentList.push({
      id: item.id,
      type: 'attachment',
      title: item.filename,
      postId: id,
      filetype: item.filetype
    })
  })

  let postContent = currentPost.content ?? ""
  //postContent = postContent.replace(/(\r\n|\n\r|\r|\n)/g, '<br />');
    

    return(
        <>
          { isLoading ? 
            <Loader />
            :
            (
              !currentPost.id ? 
              <AccessDenied />
              :
              <Container>
                <Row>
                    <Col xs={2}>
                      {
                        myRights["view_history"] ?
                          <a className="ui-icon  align-right" onClick={() => handleShowModalHistory()}><EyeIcon /></a>
                          :
                          ""

                      }

                    </Col>
                    <Col></Col>
                    <Col xs={2}>
                        <div className='folder-operations'>
                        <PrintIcon className="ui-icon" onClick={printContent} />
                        <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}}></iframe>

                          {
                            myRights["moderation"] || (currentPost && currentPost["created_by"] == profile["id"]) ?
                            <Dropdown className='operation-dropdown' drop={'start'}>
                                  <Dropdown.Toggle variant="success" className='dropdown-button'>
                                      <SettingsIcon />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                        {
                                          currentPost && currentPost["created_by"] == profile["id"] ?
                                          <Dropdown.Item>
                                            <div className="operation-card highlight-element" onClick={handleEdit}>
                                                <EditIcon />MODIFICA

                                            </div>
                                          </Dropdown.Item>
                                          :
                                          ""
                                        }
                                        {
                                          myRights["moderation"] ?
                                          <Dropdown.Item>
                                            <div className="operation-card highlight-element" onClick={handleShowModalMove}>
                                              <MoveIcon />SPOSTA
                                            </div>
                                          </Dropdown.Item>
                                          :
                                          ""
                                        }
                                        {
                                          myRights["moderation"] ?
                                          <Dropdown.Item>
                                            <div className="operation-card highlight-element" onClick={handleShowModalDelete} >
                                              <DeleteIcon />ELIMINA
                                            </div>
                                          </Dropdown.Item>
                                          :
                                          ""
                                        }
                                      
                                        
                                        
                                      
                                      
                                      
                                  </Dropdown.Menu>
                            </Dropdown>
                            :
                            ""

                          }
                          
                            
                        </div>
                    </Col>
                    
                    <Col md={12}> 
                        <div>

                        </div>
                        <div className="grey-card left-content" >
                            <div>
                                 {
                                  currentPost.userAuthor ? 
                                  <>
                                      <AccountAvatar user={currentPost.userAuthor} /> {currentPost.author} - {formatDate(currentPost.last_update ?? currentPost.published)}
                                  </>
                                  :
                                  ""
                                 }
                                {
                                  myRights['view_history'] ?
                                  <div className='right-link'>
                                    { tokenizedPath.length > 0 ?
                                          tokenizedPath.map((item,index) => {
                                            //if(tokenizedPathIds[index]  == profile.rootFolder) {
                                            if(index == tokenizedPath.length - 1)  {
                                              return <Link key={index} className='breadcrumb-element right-link ui-icon bold-hover' to={"/"}>/ Home </Link>
                                            }
                                            else {
                                              return <Link key={index} className='breadcrumb-element right-link ui-icon bold-hover' to={tokenizedPathIds[index] ? "/folder/" + tokenizedPathIds[index] : ""}>/ {item} </Link>
                          
                                            }
                                          })
                                          //   (
                                          //   <Link key={index} className='breadcrumb-element right-link ui-icon bold-hover' to={tokenizedPathIds[index] ? "/folder/" + tokenizedPathIds[index] : ""}>/ {item}</Link>
                                          // ))

                                          :
                                          ""                    
                                        }
                                  </div>
                                  :
                                  ""
                                }
                            </div>
                            <br></br>

                            <div>
                                <span className="green-rectangle-input" >&nbsp;</span>

                                <p className='post-title'>{currentPost.title}</p>

                            </div>
                            
                        </div>
                        
                    </Col>
                    
                </Row>
               
                    <>
                      <Row>
                        <Col>
                            <div className="post-textarea" dangerouslySetInnerHTML={{__html: postContent}}>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                      <LayoutCard
                        title={'ALLEGATI'}
                        variant="white"
                        actionButton={attachmentList.length > 0 ? 
                          {
                            href: "/post/" + id + "/attachment",
                            text: "Scarica tutti"
                          } : null}
                      >
                        <AttachmentsGrid
                          items={attachmentList}
                        />
                        
                      </LayoutCard>
                        
                      </Col>
                    </Row>
                    {
                      myRights['write_comment'] && !deletingPost ? 
                      <Row>
                        <Col>
                          <LayoutCard
                            title="COMMENTI"
                            variant="white"
                          >
                            <CommentsContainer
                              postId={id}
                              rights={myRights}
                            />
                          </LayoutCard>
                          
                        </Col>
                      </Row>
                      :
                      ""
                    }
                    </>
                
                
                
              </Container>

            )
            
            
            
            
            
          
          }
          <Modal show={modalState === "modal-history"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Cronologia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='modal-history-body'>
                {
                  currentPost && currentPost.history.length > 0 ?
                  <>
                    { currentPost.history.map((item,index)=> {
                      const user = users.find(x => x.id === item.user_id);
                      const className = index % 2 == 0 ? 'even' : 'odd'
                      return (
                        <div key={"view_" + index} className={className}>
                          <AccountAvatar user={user} />&nbsp;&nbsp;
                          <span>{user ? user["fullname"] : ""}&nbsp;-&nbsp;</span>
                          <span>{t(item["type"])}&nbsp;-&nbsp;</span>

                          <span>{formatDate(item["date"])}</span>
                        </div>
                    )
                    })}
                  </>
                  
                  :
                  <Loader />
                }
              </div>             
            </Modal.Body>
          </Modal>
          <Modal show={modalState === "modal-confirm-move"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Vuoi davvero spostare l'elemento selezionato in <br />
              <b>{destinationFolder ? destinationFolder.path + destinationFolder.name : ""}</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button className='ui-button' onClick={moveCurrentElement}>Sposta</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={modalState === "modal-move"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Sposta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='modal-move-body'>
              <Container>
                <Row>
                  <Col>
                        <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                  </Col>
                </Row>
                {
                  isLoadingAllFolders ?
                  <Loader />
                  :
                  <Row>
                   
                    { allAccessibleFoldersFiltered.map((folder,index)=> {
                      const item= {
                        name: folder.name,
                        mine: folder.owner_id === profile.id,
                        popupTitle: folder.path + folder.name

                      }
                      return (
                        <Col sm={3} xs={6} key={index}>
                          <FolderBox
                            item={item}
                            handleClick={() => { confirmMoveSelectedFolder(folder)}}
                          />
                        </Col>
                    )
                    })}
                  </Row>
                  
                }
              </Container>
                
              </div>             
            </Modal.Body>
          </Modal>
          <Modal show={modalState === "modal-delete"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Vuoi davvero eliminare il messaggio?
            </Modal.Body>
            <Modal.Footer>
              <Button className='ui-button' onClick={deleteCurrentPost}>Elimina</Button>
            </Modal.Footer>
          </Modal>
        </>
        
    )

}


export default  Post