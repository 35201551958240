import TitleBar from '../elements/titlebar'
import { Container, Row, Col } from 'react-bootstrap';
import FileUploader from '../multiUploader/fileUploader.js';
import {Button} from 'react-bootstrap'
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AccountAvatar from '../elements/accountAvatar';
import { addAttachment, removeAttachment, editPost } from '../../store/actions/posts';
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useEffect } from 'react';
import { getPost, getPostAuthor, getPostAttachments } from '../../store/actions/posts';
import { globalNotification } from '../../store/reducers/notifications';
import { Loader } from '../../utils/tools';
import HtmlEditor from '../elements/htmlEditor';
import { MAX_ATTACHMENT_SIZE } from '../../utils/costants';
import usePrompt from '../../utils/prompt.ts';
import { getSettings } from '../../store/actions/settings.js';
import AttachmentBox from '../elements/box/attachmentBox.js';

const EditPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const profile = useSelector(state=>state.profile);
    
    const [selectedFiles, setSelectedFiles] = useState([]);

    const currentPost = useSelector(state=>state.posts.current);
    const isLoading = useSelector(state=>state.posts.loading);
 
    const [forceClose, setForceClose] = useState(false);

    const settings = useSelector(state=>state.settings.settings);
    const [maxAttachmentSize, setMaxAttachmentSize] = useState(0)

    useEffect(()=>{
        
        dispatch(getSettings())
        
    }, [])
    useEffect(()=>{
        

        let max_attachment_size = 0
        for(let i = 0; i < settings.length; i++) {
            if(settings[i]['name'] == 'max_attachment_size') {
                max_attachment_size = settings[i]['value']
            }
        }
        if(!max_attachment_size) {
            max_attachment_size = MAX_ATTACHMENT_SIZE
        }
        setMaxAttachmentSize(max_attachment_size)

    }, [settings])

    const loadAttachments = () => {
        dispatch(getPostAttachments({id})).then((data) => {
            const items = data.payload.attachments
            let att = []

            items.forEach((item) => {
                att.push({
                    id: item.id,
                    type: 'attachment',
                    title: item.filename,
                    postId: id,
                    filetype: item.filetype
                })
            })
            setSelectedFiles(att)
        })
    }

    useEffect(()=>{
    
        dispatch(getSettings())
    
    }, [])

    useEffect(()=>{
        if(!isLoading && currentPost.id > 0) {
            //console.log(isLoading)
            if(currentPost["created_by"] && currentPost["created_by"] !== profile.data["id"])
            {
                dispatch(globalNotification({
                    status: "error",
                    message: "edit_post_denied"
                }))

                setForceClose(true)
            }
            else {
                setTitle(currentPost.title) 
                setContent(currentPost.content)
            }
            
        }
    },[isLoading])



    useEffect(()=>{
        if(forceClose) {
            navigate("/post/" + id,{ replace: true })
        }

    },[forceClose])



    useEffect(()=>{
        dispatch(getPost({id})).then(() => {
            //dispatch(getPostAuthor({id}))
            setTimeout(() => {
                document.getElementById('post-title').focus({ focusVisible: true })

            }, 3000);

            loadAttachments() 

        })        
    },[id])

    
   
    

    


    


    const handleEditorState = (state) => {
        setContent(state)
    }

    usePrompt(
        "ATTENZIONE! Le modifiche al messaggio non saranno salvate! Sei sicuro di voler uscire?",
        !forceClose
    );




    const addSelectedFile = (file) => {
        selectedFiles.push({
            title: file.name,
            type: "attachment",
            file: file,
            postId: id,
            filetype: file.type
        })
        setSelectedFiles([...selectedFiles])//trick to re-render component
    }

    const removeSelectedFile = (event, element) => {
        selectedFiles.splice(element.index,1)
        setSelectedFiles([...selectedFiles])//trick to re-render component
    }

    const modifyPost = async () => {


        if(title.length == 0) {
            dispatch(globalNotification({
                status: "error",
                message: "insert_title_and_content"
            }))
            return
        }
        const data ={
            title: title,
            content: content
        }
        dispatch(editPost({id: id,data: data})).then( async (data) => {
            //add and remove attachments
            
            if(selectedFiles.length > 0 && selectedFiles.some(el => !el.id)) { //there is something new in selected files
                dispatch(globalNotification({
                    status: "success",
                    message: "wait_for_load"
                }))
            }


            for(let i=0; i<selectedFiles.length; i++) {
                if(!selectedFiles[i].id) { //if file is not loaded yet
                    await dispatch(addAttachment({id: id, file: selectedFiles[i]["file"]}))
                }
            }

            for(let i=0; i<currentPost.attachments.length; i++) {
                let found = false
                for(let j=0; j<selectedFiles.length; j++) {
                    if(currentPost.attachments[i].id === selectedFiles[j].id) {
                        found = true
                        break;
                    }
                }
                
                if(!found) {
                    await dispatch(removeAttachment({id: id, attachmentId: currentPost.attachments[i].id}))
                }
            }
            
            setForceClose(true)
            //loadAttachments()

        })
    }
    

    

    return(
        
        <>
            {
                isLoading ?
                <Loader />
                :
                <>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="grey-card left-content" >
                                    <div>
                                        <AccountAvatar /> {currentPost.author}
                                        {/* Account avatar is always the current profile but the name could be older */}
                                    </div>
                                    <div>
                                        <span className="green-rectangle-input" >&nbsp;</span>

                                        <input id="post-title" value={title} type="text" maxLength="255" className='post-title' placeholder="Titolo" onChange={event => setTitle(event.target.value)}  />
                                    </div>
                                    
                                </div>
                                
                            </Col>
                            <Col md={4}>
                                <div className="d-grid gap-2">
                                    <Button size="lg" className="ui-button" onClick={modifyPost}>SALVA E CHIUDI</Button>
                                </div>
                                <div className="d-grid gap-2">

                                    <Button size="lg" className="ui-button grey" >
                                        <Link to={"/post/" + id}>
                                            CHIUDI SENZA SALVARE
                                        </Link>
                                    </Button>
                                
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <HtmlEditor
                                    setEditorState={(state)=>handleEditorState(state)}
                                    value={content}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <TitleBar title={"ALLEGATI"} color={"#EDEDED"} />
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <FileUploader
                                    accept={"*/*"}
                                    onFileSelectSuccess={(file) => addSelectedFile(file)}
                                    onFileSelectError={({ error }) => { dispatch(globalNotification({
                                        status: "error",
                                        message: error
                                    })) }}
                                    multiple={true}
                                    maxSize={maxAttachmentSize}
                                    >
                                    <FileUploadIcon sx={{ fontSize: 100 }} />
                                </FileUploader>
                            </Col>
                            {
                                selectedFiles.length > 0 ?
                                selectedFiles.map((item,index)=>(
                                    
                                    <Col md={2} sm={4} key={"col_" + index}>
                                        <AttachmentBox index={index} item={item} handleDelete={removeSelectedFile} handleClick={() => {}} />
                                    </Col>
                                
                                ))
                                :
                                ""
                            }
                            
                            {/* <Col sm={12}>
                                <MultiUploader
                                    postId={id}
                                ></MultiUploader>
                              
                            </Col> */}
                        </Row>
                    
                    </Container>
                </>
            }
           
        </>
        
    )

}


export default  EditPost