import EditMenu from "./editMenu";
import EditSignature from "./editSignature";
import { useSelector } from "react-redux";
import EditMapping from "./editMapping";
import EditGroups from "./editGroups";
import EditParentGroups from "./editParentGroups";
import EditSettings from "./editSettings";
import EmptyTrash from "./emptyTrash";
const AdminSettings = () => {   


    return(
        <>
            
            <EditMenu />
            <hr></hr>

            <EditGroups />
            <hr></hr>
            <EditParentGroups />
            <hr></hr>
            <EditMapping />
            <hr></hr>
            <EditSettings />
            <hr></hr>
            <EmptyTrash />
            
        </>
    )
}

export default AdminSettings;