import { createSlice } from '@reduxjs/toolkit';
import {
    getApplications,
    signOut,
    updateProfile,
    updateProfileAvatar
    
} from '../actions/profile'
import {storeTokenCookie} from '../../utils/tools'


let DEFAULT_USER_STATE = {
    loading:false,
    data:{
        id:null,
        email:"",
        username: "",
        // firstname:"",//"Domenico",
        // lastname:"",//"Cinalli",
        fullname: "",
        admin:0,
        verified:null,
        authToken: null,
        rootFolder: null,
        avatar: null,
        applications: null,


    },
    auth:null
}

export const userSlice = createSlice({
    name:'user',
    initialState:DEFAULT_USER_STATE,
    reducers:{
        setAuthenticated:(state,data)=>{
            state.data.id = data.payload.id

            state.data.fullname = data.payload.fullname
            state.data.email = data.payload.email
            state.data.username = data.payload.username

            state.data.authToken = data.payload.app_token
            state.data.rootFolder = data.payload.root_folder
            state.data.trashFolder = data.payload.trash_folder
            state.data.admin = data.payload.admin
            state.data.appendSignature = data.payload.append_signature
            state.data.signature = data.payload.signature

            state.data.avatar = data.payload.avatar_path

            state.auth = true
            storeTokenCookie(data.payload.app_token)
            
        }
    },
    extraReducers:(builder)=>{
        builder
        
        // SIGN IN
        
         
         // SIGN OUT
         .addCase(signOut.fulfilled,(state,action)=>{
            state.data = DEFAULT_USER_STATE.data
            state.auth = false;
        })
        /// UPDATE PROFILE
        .addCase(updateProfile.fulfilled,(state,data)=>{
            state.data.appendSignature = data.payload.append_signature !== undefined ? data.payload.append_signature : state.data.appendSignature
            state.data.signature = data.payload.signature !== undefined ? data.payload.signature : state.data.signature
        })


        //GET APPLICATIONS
        .addCase(getApplications.fulfilled,(state,action)=>{
            state.data.applications = action.payload
        })

        /// UPDATE PROFILE AVATAR
        .addCase(updateProfileAvatar.fulfilled,(state,action)=>{
            state.data = {...state.data,...action.payload}
         })
      
    }
})


export const { setAuthenticated } =  userSlice.actions;
export default userSlice.reducer