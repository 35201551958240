import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { LinearProgress, IconButton, Button, TextField, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAuthHeader } from '../../utils/tools';
import i18n from '../../i18n/translations';
import { useTranslation } from "react-i18next"


const FileUploader = (props) => {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation()




  // Handle drop event for files
  const handleDrop = (acceptedFiles) => {
    for(let i=0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];

      if(file) {
          if (file.size > props.maxSize) {
              props.onFileSelectError({ error: "Il file supera le dimensioni massime consentite (" + (props.maxSize / (1024 * 1024)).toFixed(2) + "MB)"});
              return
          }
  
          props.onFileSelectSuccess(file);
      }
    }
  
  };

  // Handle change event for file title
  const handleTitleChange = (fileId, title) => {
    setFiles((prevFiles) =>
      prevFiles.map((fileData) =>
        fileData.id === fileId ? { ...fileData, title } : fileData
      )
    );
  };



  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {'*/*' : []},
    multiple: props.multiple,
  });

  return (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <Typography variant="body1" className="dropzone-text">
          Trascina i file o clicca per selezionare
        </Typography>
      </div>
     
      
      
    </div>
  );
};

export default FileUploader;
