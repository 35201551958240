import { formatDate } from "../../utils/tools"
import { Link } from "react-router-dom"
import { truncate } from "../../utils/tools"
const PostPreview = ({post, variant}) => {
    return(
        <div className={variant == 'grey' ? 'grey-preview post-preview' : 'white-preview post-preview'}>
            <Link to={`/post/${post.id}`}>

                <b>{truncate(formatDate(post.published) + " | " + post.author, 90)} </b>
                <p className="post-preview-content">
                    {truncate(post.title, 195)}     
                </p>
            </Link>
                
             
        </div>
    )

}


export default  PostPreview