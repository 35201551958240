import { createSlice } from '@reduxjs/toolkit';
import { getMenu } from '../actions/menuItems';


let DEFAULT_STATE = {
    loading:false,
    menu: []
}

export const menuSlice = createSlice({
    name:'menuItems',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getMenu.pending,(state)=>{ state.loading = true })
        .addCase(getMenu.fulfilled,(state,action)=>{
            state.loading = false;
            state.menu = action.payload
        })
        .addCase(getMenu.rejected,(state)=>{ state.loading = false })

        
      
      
    }
})



export default menuSlice.reducer;
