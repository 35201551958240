

const ExtLogin = ({changeAccount = false, callbackUrl}) => {
    console.log(process.env)
    let redirectUrl = callbackUrl  ?? process.env.REACT_APP_OAUTH_SERVER_REDIRECT_URI

    let navigate_uri = `${process.env.REACT_APP_OAUTH_SERVER_URL}?client_id=${process.env.REACT_APP_OAUTH_SERVER_CLIENT_ID}&state=${process.env.REACT_APP_OAUTH_SERVER_SECRET}&redirect_uri=${redirectUrl}&scope=openid&response_type=code`

    if(changeAccount) {
        navigate_uri += "&prompt=select_account"
    }
    window.location.href = navigate_uri
    return null
}

export default ExtLogin