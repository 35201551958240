import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getMenu } from '../../store/actions/menuItems';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import { editWholeMenu } from '../../store/actions/menuItems';
import { globalNotification } from '../../store/reducers/notifications';
const EditMenu = () => {

    const [currentMenu, setCurrentMenu] = useState([])


    const menu = useSelector(state => state.menuItems.menu)
    useEffect(() => {
        let initialMenu = []
        for(let i in menu) {
            initialMenu.push({
                title: menu[i].title,
                link: menu[i].link
            })
        }
        setCurrentMenu(initialMenu)

    },[menu])

    const saveMenu = () => {
        //console.log(currentMenu)
        let error = false;
        for (let i=0;i < currentMenu.length; i++) {
            if(!currentMenu[i]['title'] || !currentMenu[i]['link']) {
                error = true;
            }
        }
        if(error) {
            dispatch(globalNotification({
                status: "error",
                message: "insert_title_and_link"
              }))
            return;
        }
        dispatch(editWholeMenu(currentMenu)).then(() => {
            dispatch(getMenu())
        })


    }
    const addItem = (element) => {
        const title = document.getElementById("new-item-title").value
        const link = document.getElementById("new-item-link").value
        if(!title || !link) {
            dispatch(globalNotification({
                status: "error",
                message: "insert_title_and_link"
              }))
            return
        }
        setCurrentMenu((currentMenu) => [...currentMenu,{
            title: title,
            link: link
        }]);
        document.getElementById("new-item-title").value = ""
        document.getElementById("new-item-link").value = ""
    }

    const editItem = (e,index,type) => {
        let newMenu = []
        for (let i in currentMenu) {
            if(index != i) {     
                newMenu.push(currentMenu[i])
            }
            else {
                let newElement= currentMenu[i]
                if(type == 'title') {
                    newElement.title = e.target.value
                }
                else {
                    newElement.link = e.target.value
                }
                newMenu.push(currentMenu[i])

            }
        }
        setCurrentMenu(newMenu);
    }

    const removeItem = (index) => {
        let newMenu = []
        for (let i in currentMenu) {
            if(index != i) {     
                newMenu.push(currentMenu[i])
            }
        }
        //console.log(newMenu)
        setCurrentMenu(newMenu);

    }

    const dispatch = useDispatch();

    useEffect(()=>{
        if(menu.length == 0) {
            dispatch(getMenu())
        }
        
        }, [])

    return(
        <>
            <h3>Gestione del menu</h3>
            <Container className='admin-options'>
                <Row>
                    <Col xs={4}>
                        <b>TItolo</b>
                    </Col>
                    <Col xs={4}>
                        <b>Link</b>
                    </Col>
                    <Col xs={4}>
                    </Col>
                </Row> 
            { 
                currentMenu && currentMenu.length > 0 ? 
                currentMenu.map((item,index)=>(
                        <Row key={index}>
                            <Col xs={4}>
                                <input type="text" placeholder="Titolo" defaultValue={item ? item.title : ""} onChange={(e) => editItem(e,index,"title")} ></input>
                
                        
                            </Col>
                            <Col xs={4}>
                                <input type="text" placeholder="Link" defaultValue={item ? item.link : ""} onChange={(e) => editItem(e,index,"link")} ></input>
                            </Col>
                            <Col xs={4}>
                                <DeleteIcon className='ui-icon' onClick={() => removeItem(index)} />

                            </Col>
                        </Row> 
                    ))
                :
                    ""
            }
            <Row className='menu-item'>
                <Col xs={4}>
                    <input id="new-item-title" type="text" placeholder="Titolo"></input>
                </Col>
                <Col xs={4}>
                    <input id="new-item-link" type="text" placeholder="Link"></input>
                </Col>
                <Col xs={4}>
                    <AddIcon className='ui-icon' onClick={addItem} />
                </Col>
            </Row> 
            <Row>
                <Col md={{span: 3, offset: 9 }}>
                    <Button className='ui-button' onClick={saveMenu}>
                        Salva menu
                    </Button>
                </Col>
            </Row>
            </Container>
            

            
        </>
    )
}

export default EditMenu;