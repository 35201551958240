import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { LinearProgress, IconButton, Button, TextField, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAuthHeader } from '../../utils/tools';
import i18n from '../../i18n/translations';
import { useTranslation } from "react-i18next"

const MultiUploader = ({ onUpload, folderId, postId = 0 }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation()

  // Handle drop event for files
  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Date.now() + Math.random(),
      title: file.name,
      uploadProgress: 0,
      uploading: false,
      uploadCancelled: false,
      status: '',
      result: "",
      editable: true, // Inizialmente il titolo è modificabile
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Handle change event for file title
  const handleTitleChange = (fileId, title) => {
    setFiles((prevFiles) =>
      prevFiles.map((fileData) =>
        fileData.id === fileId ? { ...fileData, title } : fileData
      )
    );
  };

  // Handle remove file from list
  const handleRemoveFile = (fileId) => {
    setFiles((prevFiles) => prevFiles.filter((fileData) => fileData.id !== fileId));
  };

  // Handle upload button click
  const handleUpload = async () => {
    setUploading(true);

    const uploadFile = async (fileData) => {
      const { file, id } = fileData;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', fileData.title);

      setFiles((prevFiles) =>
        prevFiles.map((data) => (data.id === id ? { ...data, uploading: true } : data))
      );

      try {
        const source = axios.CancelToken.source();
        fileData.cancelToken = source;

        setFiles((prevFiles) =>
          prevFiles.map((data) =>
            data.id === id ? { ...data, uploadProgress: 1 } : data
          )
        );
        const post = new FormData();
        post.append('title',fileData.title ?? file.name)
        post.append('folder_id',folderId)
        post.append('type','attachment')
        post.append('content','')


        let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,post,getAuthHeader());



        const postId = response.data.id

        const formData = new FormData();
    
  
    
        formData.append( 
            "attachment", 
            file, 
            file.name
        );
        let config = getAuthHeader();
        config['cancelToken'] = source.token
        config['onUploadProgress'] = (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setFiles((prevFiles) =>
            prevFiles.map((data) =>
              data.id === id ? { ...data, uploadProgress: progress } : data
            )
          );
        }

        response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${postId}/attachments`, formData, config)


        setFiles((prevFiles) =>
          prevFiles.map((data) =>
            data.id === id ? { ...data, uploading: false, uploadProgress: 100, result: response.data.message, status: response.data.status } : data
          )
        );

        //console.log('File uploaded successfully:', response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log('Upload cancelled:', error.message);
        } else {
          //console.error('Error uploading file:', error.message);
        }
      }
    };

    await Promise.all(files.filter((fileData) => fileData.uploadProgress !== 100).map(uploadFile));
    setUploading(false);
    onUpload()
  };

  // Handle cancel upload for a file
  const handleCancelUpload = (fileId) => {
    setFiles((prevFiles) =>
      prevFiles.map((data) =>
        data.id === fileId ? { ...data, uploadCancelled: true, uploading: false } : data
      )
    );
    const fileData = files.find((data) => data.id === fileId);
    if (fileData && fileData.cancelToken) {
      fileData.cancelToken.cancel('Upload cancelled by user.');
    }
  };

  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {'*/*' : []},
    multiple: true,
  });

  return (
    <div>
      { !uploading && (<div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <Typography variant="body1" className="dropzone-text">
          Trascina i file o clicca per selezionare
        </Typography>
      </div>
      )}
      
      <Box mt={3}>
        {files.map((fileData) => (
          <Box key={fileData.id} mb={2} className="file-item">
            <Typography variant="body2" className="file-name">
              {fileData.file.name}
            </Typography>
            
            <Box display="flex" alignItems="center" mt={1}>
              
              <TextField
                label="Titolo"
                variant="outlined"
                fullWidth
                value={fileData.title}
                onChange={(e) => handleTitleChange(fileData.id, e.target.value)}
                disabled={fileData.uploading || uploading || fileData.uploadProgress === 100 } // Disabilita il titolo durante l'upload globale
                className="file-title"
                size="small"
              />
              {fileData.uploadProgress !== 100 && (
                <IconButton onClick={() => handleRemoveFile(fileData.id)} className="delete-button">
                  <DeleteIcon />
                </IconButton>
              )}
              
              {fileData.uploadProgress !== 100  && fileData.uploading && (
                <IconButton
                  onClick={() => handleCancelUpload(fileData.id)}
                  className="cancel-upload-button"
                >
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
            {fileData.uploading && (
            <Box flex={1} ml={2}>
                
                <LinearProgress
                  variant="determinate"
                  value={fileData.uploadProgress}
                  className="upload-progress"
                />
              
            </Box>
            )}
            {fileData.uploadProgress === 100 && (
            <Box flex={1} ml={2} className={fileData.status === 'error' ? "red-text" : "green-text"}>
                
                {t(fileData.result)}
              
            </Box>
            )}
          </Box>
        ))}
      </Box>
      {postId === 0 && (
        <Box mt={2}>
          <Button
            className='green-button'
            variant="contained"
            onClick={handleUpload}
            disabled={files.length === 0 || files.some((file) => file.uploading)}
            fullWidth
          >
            Carica
          </Button>
        </Box>

      )}
      
      
    </div>
  );
};

export default MultiUploader;
