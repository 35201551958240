
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useRef } from 'react';
import { useEffect } from 'react';
import { updateGroup, deleteGroup, addGroup } from '../../store/actions/groups';
import { globalNotification } from '../../store/reducers/notifications';
const GroupRow = ({item, onUpdate}) => {
  const inputName = useRef(null)
  const inputIsDefault = useRef(null)
  const [modalState, setModalState] = useState("close")

  const dispatch = useDispatch();

  

  const handleShowModalDelete = () => {
    setModalState("modal-delete")
  }
  
  const handleClose = () => {
   setModalState("close")
  }



  const addCurrentGroup = () => {
    if(inputName.current.value == "") {
      dispatch(globalNotification({
        status: "error",
        message: "insert_group_name"
      }))
      return
    }
    dispatch(addGroup({
      name: inputName.current.value,
      is_default: inputIsDefault.current.checked
    })).then(() => {
      inputName.current.value = ""
      inputIsDefault.current.checked = false
      onUpdate()
    })
    
  }

  const deleteCurrentGroup = () => {
    dispatch(deleteGroup({groupId: item.id})).then(() => {
      handleClose()
      onUpdate()
    })
    
  }

  const editCurrentGroup = () => {
    if(inputName.current.value == "") {
      dispatch(globalNotification({
        status: "error",
        message: "insert_group_name"
      }))
      return
    }
    dispatch(updateGroup({groupId: item.id, data: {
      name: inputName.current.value,
      is_default: inputIsDefault.current.checked
    }}))
  }



  return(
    <>
      <Row>
        <Col xs={3}>
            <input type="text" ref={inputName} placeholder="Nome" defaultValue={item ? item.name : ""} ></input>
        </Col>
        <Col xs={3}>
            <input type="radio" name="default-group" ref={inputIsDefault} defaultChecked={item ? item.is_default : ""} ></input>
        </Col>
        <Col xs={3}>
            <input disabled type="checkbox" defaultChecked={item ? item.system : ""} ></input>
        </Col>

        <Col xs={3}>
            {
              item  ?
                
                  !item.system ?
                    <>
                      <SaveIcon className='ui-icon' onClick={() => editCurrentGroup()} />
                      <DeleteIcon className='ui-icon' onClick={() => handleShowModalDelete()} />
                    </>
                    :
                    ""
                
               
              :
                <>
                  <AddIcon className='ui-icon' onClick={() => addCurrentGroup()} />

                </>

            }
            

        </Col>
    </Row>
    <Modal show={modalState === "modal-delete"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero eliminare il gruppo <b>{inputName.current ? inputName.current.value : ""}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={deleteCurrentGroup}>Elimina</Button>
        </Modal.Footer>
      </Modal>
    </>
    
  )

}


export default  GroupRow