import { createSlice } from '@reduxjs/toolkit';
import { 
    getUsers
} from '../actions/users'


let DEFAULT_STATE = {
    loading:false,
    users: []
}

export const usersSlice = createSlice({
    name:'users',
    initialState:DEFAULT_STATE,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getUsers.pending,(state)=>{ state.loading = true })
        .addCase(getUsers.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.users = action.payload 
        })
        .addCase(getUsers.rejected,(state)=>{ state.loading = false })
        
      
    }
})


export default usersSlice.reducer