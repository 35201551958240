import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { getAuthHeader } from "../../utils/tools";
import EmailPreview from '../elements/emailPreview';


export default function EmailViewer() {
    const [zimbraError, setZimbraError] = useState(false);

    const [lastEmails, setLastEmails] = useState([]);
    const getLastEmails = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/email`, {
            headers: getAuthHeader().headers
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            if(data.status == "error") {
                setZimbraError(true)
            }
            else {
                setLastEmails(data);
            }

        // const updatedOptions = myJson.map((item) => {
        //     return {id: item.id, title: item.path + item.name };
        // });
        // setOptions(updatedOptions);
        });
        // const res = axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/email`,getAuthHeader());
        // if(res.response.status != 200) {
        //     setZimbraError(true)
        // }
        // else {
        // }



    };

    useEffect(() => {
        getLastEmails()
    }, [])


    return (
        <div className="email-preview-grid">
            {
                zimbraError ? 
                "Effettuare la migrazione a Zimbra per visualizzare i messaggi di posta." :
                <div className="email-table">
                {
                    lastEmails && lastEmails.length > 0 ? 
                    <>
                    {
                        lastEmails.slice(0,5).map((item,index)=>(
                            <EmailPreview key={index} email={item} variant={index % 2 ?  'white' : 'grey'} />
                        ))
                    }
                    </>
                    :
                    "Nessuna email presente"
                }
                </div>
                
            }
            
        </div>
    );
}