import { createSlice } from '@reduxjs/toolkit';
import { getSettings } from '../actions/settings';


let DEFAULT_STATE = {
    loading:false,
    settings: []
}

export const menuSlice = createSlice({
    name:'settings',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getSettings.pending,(state)=>{ state.loading = true })
        .addCase(getSettings.fulfilled,(state,action)=>{
            state.loading = false;
            state.settings = action.payload
        })
        .addCase(getSettings.rejected,(state)=>{ state.loading = false })

      
      
    }
})



export default menuSlice.reducer;
