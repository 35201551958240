import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const CommentEditor = ({value, initialValue, setEditorState}) =>{
    const editorRef = useRef(null);
    const onEditorChange = () => {
        if (editorRef.current) {
            const HTMLdata = editorRef.current.getContent()
            setEditorState(HTMLdata)
        }
        

    };

    // Prevent Bootstrap dialog from blocking focusin
    document.addEventListener('focusin', (e) => {
        if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
        }
    })

    return(
        <div className='html-editor-container'>
            <Editor
                apiKey="s91lqgl30mu7vfklzcn2ur1iceq1dyca934s3vepkuni7wk9"
                onInit={(evt, editor) => {
                    editorRef.current = editor
                    editor.selection.select(editor.getBody(), true);
                    editor.selection.collapse(false);
                    // editor.setContent(value)
                }}
                value={value}
                initialValue={initialValue}
                
                init={{
                    //forced_root_block: 'div',
                    paste_data_images: false,
                    statusbar: false,
                    branding: false,
                    height: 400,
                    language: 'it',
                    auto_focus: true,
                    //newline_behavior: 'invert',
                    content_style: "p {margin: 0}",
                    toolbar_mode: 'sliding',
                    menubar: false,
                    //plugins: 'link',
                    toolbar: 'undo redo | ' +
                    'bold italic underline strikethrough removeformat '
                    //'link' 



                }}

               
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}

                onEditorChange={onEditorChange}
            />

        </div>
    )


}

export default CommentEditor;