import { createSlice } from '@reduxjs/toolkit';
import { 
    addGroup,
    getGroups, getLDAPGroups, getMapping, getParentGroups
} from '../actions/groups'


let DEFAULT_STATE = {
    loading:false,
    groups: [],
    ldapGroups: [],
    mapping: [],
    parentGroups: []
}

export const usersSlice = createSlice({
    name:'groups',
    initialState:DEFAULT_STATE,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getGroups.pending,(state)=>{ state.loading = true })
        .addCase(getGroups.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.groups = action.payload 
        })
        .addCase(getGroups.rejected,(state)=>{ state.loading = false })

        .addCase(getMapping.pending,(state)=>{ state.loading = true })
        .addCase(getMapping.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.mapping = action.payload 
        })
        .addCase(getMapping.rejected,(state)=>{ state.loading = false })

        


        // .addCase(addGroup.pending,(state)=>{ state.loading = true })
        // .addCase(addGroup.fulfilled,(state,action)=>{
        //     state.loading = false;
        //     state.current.content.unshift(action.payload)
        // })
        // .addCase(addGroup.rejected,(state)=>{ state.loading = false })

        .addCase(getParentGroups.pending,(state)=>{ state.loading = true })
        .addCase(getParentGroups.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.parentGroups = action.payload 
        })
        .addCase(getParentGroups.rejected,(state)=>{ state.loading = false })

        .addCase(getLDAPGroups.pending,(state)=>{ state.loading = true })
        .addCase(getLDAPGroups.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.ldapGroups = action.payload 
        })
        .addCase(getLDAPGroups.rejected,(state)=>{ state.loading = false })
    }
})


export default usersSlice.reducer