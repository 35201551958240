

import { Container, Row, Col, Button } from 'react-bootstrap';


import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react'
import LayoutCard from '../../hoc/layoutCard';
import { simpleSearch, resetSearch } from '../../store/actions/search';
import 'react-datepicker/dist/react-datepicker.css'
import { Loader } from '../../utils/tools';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AdvancedSearchIcon from '@mui/icons-material/ScreenSearchDesktop';
import FolderSearchIcon from '@mui/icons-material/Pageview';
import FolderAutocompleter from '../elements/autocompleter/folderAutocompleter';
import { storeUserCookie } from '../../utils/tools';
import { itIT } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { formatDate } from '../../utils/tools';
import { IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import {Tooltip}  from '@mui/material';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import { getUserCookie } from '../../utils/tools';
import { deletePost, getPost, movePost } from '../../store/actions/posts';

const Search = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const q = queryParams.get("q")

    const items = useSelector(state=>state.search).results

    //reset page count on data grid
    const [paginationModel, setPaginationModel] = useState(getUserCookie('grid-pagination') ? {...getUserCookie('grid-pagination'), page: 0} : {
        pageSize: 50,
        page: 0,
    });

    useEffect(()=>{
          setFilteredItems(items)
      },[items])
    

      const showPost = (params) => {
        const element = params.row
        //console.log(element)
        if(!element.deleted) {
            
            if(element.type == "link" || element.type == "attachment")
            {
                //NOTE i must read the post to mark as read
                dispatch(getPost({id: element.id}))
                window.open(element.link, "post_" + params.id);
                //refreshResults()

                
            }
            else if(element.type == "post")
            {
                window.open("/post/" + params.id, "post_" + params.id)
            }
        }
    }

    const columns = [
    // {
    //     field: 'unread',
    //     headerName: '●',
    //     filterable: false,
    //     sortable: true,
    //     width: 12,
    //     cellClassName: 'centered-text',

    //     renderCell: (param) => {
    //         const elem = param.row

    //         return (
    //             <>
    //             {
    //                 elem["unread"] ?
    //                 <Tooltip title={"Da leggere"}><div className="red-dot"></div></Tooltip>
    //                 :
    //                 (
    //                     elem["new_comment"] ?
    //                     <Tooltip title={"Nuovo commento aggiunto"}><div className="green-dot"></div></Tooltip>
    //                     :
    //                     ""
    //                 )
                    
    //             }
    //             </>
                
    //         )
    //     }
        
    // },
    {
        field: 'author',
        headerName: 'Autore',
        minWidth: 200,
        flex:5,
        renderCell: (param) => {
            return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
        }
    },
    { 
        field: 'title',
        headerName: 'Oggetto',
        minWidth: 400,
        flex:10,
        renderCell: (param) => {
            const row = param.row
            switch(row.type) {
                case 'post':
                    return <Tooltip title={row.title}><span>{row.title}</span></Tooltip>
                case 'attachment':
                    return <Tooltip title={row.content}><span>{row.title}</span></Tooltip>
                case 'link':
                    return <Tooltip title={row.link}><span>{row.title}</span></Tooltip>
                
            }
        }
    },
    { 
        field: 'path',
        headerName: 'Path',
        minWidth: 400,
        flex:8,
        renderCell: (param) => {
            return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
        }
        
    },
    {
        field: 'last_update', headerName: 'Data', width: 200,
        renderCell: (param) => {
            //const formattedDate = (param.row.last_update ?? param.row.published)
            //NOTE: rendercell is called AFTER sorting
            const formattedDate = formatDate(param.value)
            return <Tooltip title={formattedDate}><span>{formattedDate}</span></Tooltip>
        }

    },       
    {
        field: 'type',
        headerName: 'Tipologia',
        filterable: false,
        sortable: false,
        width: 80,
        renderCell: (param) => {
            switch(param.value) {
                case 'post':
                    return <Tooltip title="Post"><PostIcon sx={{ fontSize: 20 }} /></Tooltip>
                case 'link':
                    return <Tooltip title="Link"><LinkIcon  sx={{ fontSize: 20 }}  /></Tooltip>
                case 'attachment':
                    return <Tooltip title="Allegato"><AttachmentIcon sx={{ fontSize: 20 }}  /></Tooltip>
            }
        }
    }
    ];

    const [sortModel, setSortModel] = useState(getUserCookie('grid-sort') &&
    getUserCookie('grid-sort').length > 0 &&
    columns.some(x => x.field == getUserCookie('grid-sort')[0]["field"]) ? getUserCookie('grid-sort') : [{
        field: 'last_update',
        sort: 'desc'
    }]);

    const inputTitle = useRef(null)
    const inputAuthor = useRef(null)
    const subFolderSearch = useRef(null)

 
    const [targetFolder, setTargetFolder] = useState(0)

    const [currentSearch, setCurrentSearch] = useState({
        title: "",
        author: "",
        page: "",
        subfolder_search: 0
    })

    const [isLoading, setIsLoading] = useState(false)



    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        

        dispatch(resetSearch())
    },[])

    useEffect(()=>{
        inputTitle.current.value = q
        inputAuthor.current.value = ""
        refreshResults()

    },[q])


    const refreshResults = () => {
        setCurrentSearch({
            title: inputTitle.current.value,
            author: inputAuthor.current.value,
            subfolder_search: subFolderSearch.current.checked ? 1 : 0,
            page: 0,
            folder_id: targetFolder
        })
    }

    useEffect(() => {
        function handleKeyDown(e) {
            if(e.keyCode == 13 && !isLoading) {
                refreshResults()
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(()=>{
        //console.log(currentSearch)
        const isEmpty = Object.values(currentSearch).every(x => x === null || x === '' || x === 0);
        if(isEmpty) {
            dispatch(resetSearch())
        }
        else {
            setIsLoading(true)

            dispatch(simpleSearch({
                data: {
                    title: currentSearch.title,
                    author: currentSearch.author,
                    folder_id: currentSearch.folder_id,
                    subfolder_search: currentSearch.subfolder_search

                },
                page: currentSearch.page
            })).then(() =>{
                setIsLoading(false)
            })
        }
        

        
    },[currentSearch])

    const [filteredItems, setFilteredItems] = useState([])
    const inputFilter = useRef(null)

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {
            return el.title.toLowerCase().includes(keyword) || el.author.toLowerCase().includes(keyword) ;
        }))
    }

  return(
    <>
       <LayoutCard
            title={"RICERCA BASE"}
        >
            <Container>
                <Row>
                    <Col md={6}>
                        <b>Titolo</b><br></br>
                        <input className="search-input" ref={inputTitle} type="text" placeholder="Cerca nel titolo" /><br></br>
                    </Col>
                    <Col md={6}> 
                        <b>Autore</b><br></br>
                        <input className="search-input" ref={inputAuthor} type="text" placeholder="Autore" /><br></br>

                       
                        

                    </Col>
                    <Col md={6}>
                        <b>Cartella</b><br></br>
                        <FolderAutocompleter setSelected={setTargetFolder} />

                    </Col>
                </Row>
                <Row>
                <Col sm={{span: 6 }}>
                    <input id="subfolder-search" defaultChecked={false} type="checkbox" ref={subFolderSearch} /> <label htmlFor="subfolder-search">Ricerca nelle sottocartelle</label>

                    </Col>
                    <Col sm={{span: 2, offset: 4 }}>
                        <Button className="green-button btn-block" onClick={refreshResults}>Cerca</Button>
                    </Col>
                </Row>
                
                </Container>
        </LayoutCard>
        <Container>
            <Row>
                    <Col sm={{span: 3, offset: 6}}>
                        <Button className="grey-button btn-block" onClick={() => {navigate("/folder-search")}}>
                            <FolderSearchIcon/> Cerca cartelle
                        </Button>

                    </Col>
                    <Col sm={{span: 3 }}>
                        <Button className="ui-button btn-block" onClick={() => {navigate("/advanced-search")}}>
                            <AdvancedSearchIcon/> Ricerca avanzata
                        </Button>

                    </Col>
                    
                </Row>
                
        </Container>
        <LayoutCard
            title={"RISULTATI"}
            variant="white"
        >
            
            {
                isLoading ? 
                <Loader />
                :
                <>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                    <div style={{ height: 650, width: '100%' }}>
                        <DataGridPro
                            
                            sortModel= {sortModel}
                            onSortModelChange={(model) => {
                                //console.log(model)
                                setSortModel(model)
                                storeUserCookie('grid-sort',model)
                            }}
                            disableRowSelectionOnClick
                            paginationModel={paginationModel}
                            onPaginationModelChange={(param) => {
                                setPaginationModel(param)
                                storeUserCookie('grid-pagination', param)

                            }}
                            pagination={true}
                            pageSizeOptions={[50,100,200,300]}
                            rowsPerPageOptions={[50, 100, 200,300]}
                            
                            checkboxSelection={false}
                            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                            rows={filteredItems}
                            columns={columns}
                            onRowClick={showPost}
                            
                         
                            getRowClassName={(params) =>
                                params.row.deleted ? 'deleted-row' : ''
                            }
                            isRowSelectable={(dataIndex, selectedRows) => {
                                return !dataIndex.row.deleted 
                            }}
                        />
                    </div>
                </>
                

            }
            
        </LayoutCard>
       
    </>
  )

}


export default  Search