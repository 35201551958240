import { createSlice } from '@reduxjs/toolkit';

import { getCommonFolder, getTrash, getAlerts, getNotices } from '../actions/specialFolder';

let DEFAULT_STATE = {
    loading:false,
    commonFolder: [],
    noticeFolder: [],
    forumFolder: [],
    alertsFolder: [],
    trash: [],
    unseenAlerts: []
}

export const specialFoldersSlice = createSlice({
    name:'specialFolders',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getTrash.pending,(state)=>{ state.loading = true })
        .addCase(getTrash.fulfilled,(state,action)=>{
            state.loading = false;
            state.trash = action.payload
        })
        .addCase(getTrash.rejected,(state)=>{ state.loading = false })

        .addCase(getCommonFolder.pending,(state)=>{ state.loading = true })
        .addCase(getCommonFolder.fulfilled,(state,action)=>{
            state.loading = false;
            state.commonFolder = action.payload
        })
        .addCase(getCommonFolder.rejected,(state)=>{ state.loading = false })
    
        .addCase(getNotices.pending,(state)=>{ state.loading = true })
        .addCase(getNotices.fulfilled,(state,action)=>{
            state.loading = false;
            state.noticeFolder = action.payload
        })
        .addCase(getNotices.rejected,(state)=>{ state.loading = false })

        .addCase(getAlerts.pending,(state)=>{ state.loading = true })
        .addCase(getAlerts.fulfilled,(state,action)=>{
            state.loading = false;
            state.alertsFolder = action.payload.posts
        })
        .addCase(getAlerts.rejected,(state)=>{ state.loading = false })


        // .addCase(getUnseenAlerts.pending,(state)=>{ state.loading = true })
        // .addCase(getUnseenAlerts.fulfilled,(state,action)=>{
        //     state.loading = false;
        //     state.unseenAlerts = action.payload
        // })
        // .addCase(getUnseenAlerts.rejected,(state)=>{ state.loading = false })

        // .addCase(getForum.pending,(state)=>{ state.loading = true })
        // .addCase(getForum.fulfilled,(state,action)=>{
        //     state.loading = false;
        //     state.forumFolder = action.payload
        // })
        // .addCase(getForum.rejected,(state)=>{ state.loading = false })
       
    }
})


export default specialFoldersSlice.reducer;
