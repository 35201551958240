import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { DataGridPro } from '@mui/x-data-grid-pro';

import DownloadIcon from '@mui/icons-material/Download';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, GridRowsProp, GridColDef, itIT} from '@mui/x-data-grid';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete'
import MoveIcon from '@mui/icons-material/MoveDown'
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deletePost, getPost, movePost, markPosts, deletePosts, editPosts, movePosts } from '../../store/actions/posts';
import { useSelector } from 'react-redux';
import { Loader, formatDate, getUserCookie, storeTokenCookie, storeUserCookie } from '../../utils/tools';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import MarkUnreadIcon from '@mui/icons-material/Markunread';
import MarkReadIcon from '@mui/icons-material/MarkAsUnread';
import { globalNotification } from '../../store/reducers/notifications';

import { editPost } from '../../store/actions/posts';
import {Tooltip}  from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import { current } from '@reduxjs/toolkit';
import FolderBox from './box/folderBox';

//import { getAllAccessibleFolders } from '../../store/actions/folders';
const PostsGrid = ({
    items,
    handleReload,
    readOnly,
    showDeletedElements,
    getPosts
}) => {

    const profile = useSelector(state=>state.profile.data)

    const dispatch = useDispatch()

    const inputLinkName = useRef(null)
    const inputLinkUrl = useRef(null)

    const inputAttName = useRef(null)


    const [modalState, setModalState] = useState("close")
    const [currentAttName, setCurrentAttName] = useState("")
    const [currentAttFilename, setCurrentAttFilename] = useState("")

    const [currentLinkName, setCurrentLinkName] = useState("")
    const [currentLinkUrl, setCurrentLinkUrl] = useState("")


    const allAccessibleFolders =  useSelector(state=>state.folders).all;
    const isLoadingAllFolders = useSelector(state=>state.folders.loadingAll);
    const isLoadingPosts = useSelector(state=>state.posts.loading);

    

    const [paginationModel, setPaginationModel] = useState(getUserCookie('grid-pagination') ? getUserCookie('grid-pagination') : {
        pageSize: 50,
        page: 0,
    });

    // const handleDownloadAllAttachments = () => {
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/attachments`
          
    //         downloadAttachment(url).catch(error => {
    //             dispatch(globalNotification({
    //                 status: "error",
    //                 message: error
    //             }))
    //         })
    //   }
    

    const [allAccessibleFoldersFiltered, setAllAccessibleFoldersFiltered] = useState(allAccessibleFolders)


    useEffect(()=>{
        setAllAccessibleFoldersFiltered(allAccessibleFolders)
    },[allAccessibleFolders])

    useEffect(() => {
        if (modalState == "modal-edit-link") {
            inputLinkName.current.value = currentLinkName
            inputLinkUrl.current.value = currentLinkUrl

        }
        else if (modalState == "modal-edit-att") {
            inputAttName.current.value = currentAttName

        }
      }, [modalState]);


    const inputFolderFilter = useRef(null)
  
    const folderFilter = () => {
      const keyword = inputFolderFilter.current.value.toLowerCase()
        setAllAccessibleFoldersFiltered(allAccessibleFolders.filter(function (el) {
              return el.title.toLowerCase().includes(keyword);
          }))
    }

   


    const [ selectedDestination, setSelectedDestination ] = useState({})


    const setDestination = (destinationFolder, restore = false) => {
        setSelectedDestination({folder: destinationFolder, restore: restore})
        if(selectedRows.length > 0) {
            handleClose()
            setModalState("modal-confirm-move")
        }
       
    }


    const moveSelectedPosts = () => {
        if(selectedRows.length > 0) {
            
            handleClose()
            dispatch(editPosts({ids: selectedRows, folder_id: selectedDestination.folder.id, restore: selectedDestination.restore})).then(() => {
                handleReload()
            })

            
        }
       
    }

    

    const editLink = () => {
        const currentId = selectedRows[0]
        const data ={
            title: inputLinkName.current.value,
            link: inputLinkUrl.current.value

        }
        dispatch(editPost({id: currentId,data: data})).then((data) => {
            handleReload()
        })
        setModalState("close")
    }

    const editAttachment = () => {
        const currentId = selectedRows[0]
        const data ={
            title: inputAttName.current.value,

        }
        dispatch(editPost({id: currentId,data: data})).then((data) => {
            handleReload()
        })
        setModalState("close")
    }

    



    // const deleteSelectedPosts = (e,index = 0) => {
    //     if(index == 0) {
    //         handleClose()
    //     }
        
    //     if(index >= selectedRows.length) {
    //         handleReload()
    //         setSelectedRows([])
    //     }
    //     else {
            
    //         dispatch(deletePost({id: selectedRows[index]})).then(() => {
    //             deleteSelectedPosts(e,index + 1)
    //         })
    //     }
    // }

    const deleteSelectedPosts = (e) => {
        handleClose()
        dispatch(deletePosts({ids: selectedRows})).then(() => {
            handleReload()
        })
    }

    const [markAllAsRead, setMarkAllAsRead] = useState(true)

    const markSelectedPosts = () => {
        if(selectedRows.length > 0) {
            handleClose()
            dispatch(editPosts({ids: selectedRows, mark_as_read: markAllAsRead})).then(() => {
                handleReload()
            })
        }
       
    }

    const handleClose = () => {
        setModalState("close")
    }
    const markAsRead = (e, currentId, read)  => {
        e.stopPropagation()
        const data ={
            mark_as_read: read
        }
        dispatch(editPost({id: currentId,data: data})).then((data) => {
            handleReload()
        })
    }

    const downloadSelectedAttachments = () => {
        if(selectedRows.length > 0) {
            console.log(selectedRows)
            navigate("attachments/", {state:{ids: selectedRows}})
        }
    }

    const handleShowModalMove = (e, currentElement) => {
        if(currentElement) { // from single row
            e.stopPropagation()
            setSelectedRows([currentElement])
            setAllAccessibleFoldersFiltered(allAccessibleFolders)
            setModalState("modal-move")
        }
        else if(selectedRows.length > 0) {
            setAllAccessibleFoldersFiltered(allAccessibleFolders)
            setModalState("modal-move")
        }
    }

    const handleEdit = (e, currentElement) => {
        e.stopPropagation()

        if(currentElement.type == 'link') { // from single row
            setSelectedRows([currentElement.id])
            setCurrentLinkName(currentElement.title)
            setCurrentLinkUrl(currentElement.link)

            setModalState("modal-edit-link")
        }
        else if(currentElement.type == 'attachment') { // from single row
            setSelectedRows([currentElement.id])
            setCurrentAttName(currentElement.title)
            setCurrentAttFilename(currentElement.content)

            setModalState("modal-edit-att")
        }
        else {
            navigate("/post/" + currentElement.id + "/edit")
        }


    }

  

    const handleShowModalDelete = (e, currentElement) => {
        if(currentElement) { // from single row
            e.stopPropagation()
            setSelectedRows([currentElement])
            setModalState("modal-delete")

        }
        else if(selectedRows.length > 0) {
            setModalState("modal-delete")
        }
    }

    const handleShowModalMark = (markAllAsRead) => {
        setMarkAllAsRead(markAllAsRead)
        
        if(selectedRows.length > 0) {
            setModalState("modal-mark")
        }
    }
    


    const handleShowModalRestore = (e, currentElement) => {
        if(currentElement) { // from single row
            e.stopPropagation()
            setSelectedRows([currentElement])
            setModalState("modal-restore")

        }
        // else if(selectedRows.length > 0) {
        //     setModalState("modal-restore")
        // }
      }

   
      const extendedColumns = [
        {
            field: 'delete_date', headerName: 'Data eliminazione', width: 200,
            renderCell: (param) => {
                if(!param.row.deleted) {
                    return ""
                }
                else {
                    const formattedDate = formatDate(param.value)
                    return <Tooltip title={formattedDate}><span>{formattedDate}</span></Tooltip>
                }
            }

        },
        { 
            field: 'deleted_by_user',
            headerName: 'Post eliminato da',
            width: 200,
            renderCell: (param) => {
              return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
        
        },     

      ]
   
      const columns = [
        {
            field: 'unread',
            headerName: '●',
            filterable: false,
            sortable: true,
            width: 12,
            cellClassName: 'centered-text',

            renderCell: (param) => {
                const elem = param.row

                return (
                    <>
                    {
                        elem["unread"] ?
                        <div className="red-dot" title="Da leggere"></div>
                        :
                        (
                            elem["new_comment"] ?
                            <div className="green-dot" title="Nuovo commento aggiunto"></div>

                            :
                            ""
                        )
                        
                    }
                    </>
                    
                )
            }
            
        },
        {
            field: 'author',
            headerName: 'Autore',
            minWidth: 200,
            flex:5,
            renderCell: (param) => {
                return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
        },
        { 
            field: 'title',
            headerName: 'Oggetto',
            minWidth: 400,
            flex:10,
            renderCell: (param) => {
                const row = param.row
                switch(row.type) {
                    case 'post':
                        return <Tooltip title={row.title}><span>{row.title}</span></Tooltip>
                    case 'attachment':
                        return <Tooltip title={row.content}><span>{row.title}</span></Tooltip>
                    case 'link':
                        return <Tooltip title={row.link}><span>{row.title}</span></Tooltip>
                    
                }
            }
        },
        { field: 'last_update', headerName: 'Data', width: 200,
        
            renderCell: (param) => {
                const value = formatDate(param.value)
                return <Tooltip title={value}><span>{value}</span></Tooltip>
            }

        },       
        {
            field: 'type',
            headerName: 'Tipologia',
            filterable: false,
            sortable: false,
            width: 80,
            renderCell: (param) => {
                switch(param.value) {
                    case 'post':
                        return <Tooltip title="Post"><PostIcon sx={{ fontSize: 20 }} /></Tooltip>
                    case 'link':
                        return <Tooltip title="Link"><LinkIcon  sx={{ fontSize: 20 }}  /></Tooltip>
                    case 'attachment':
                        return <Tooltip title="Allegato"><AttachmentIcon sx={{ fontSize: 20 }}  /></Tooltip>
                }
            }
        },
        {
            field: 'operations',
            headerName: 'Operazioni',
            filterable: false,
            sortable: false,
            width:200,
            renderCell: (param) => {
                const elem = param.row
                if(!elem.deleted) {
                    return (
                        <>
                             {
                                !elem.unread ? 
                                
                                <Tooltip title="Segna come non letto">

                                    <IconButton onClick={(e) => markAsRead(e, elem.id, false)} >
                                        <MarkUnreadIcon />
                                    </IconButton>
                                </Tooltip>
                                
                                :
                                <Tooltip title="Segna come letto">

                                    <IconButton onClick={(e) => markAsRead(e, elem.id, true)} >
                                        <MarkReadIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                               
                            {
                                !readOnly ?
                                <>
                                    <Tooltip title="Modifica">
                                        <IconButton aria-label="move" onClick={(e) => handleEdit(e, elem)} >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Sposta">
                                        <IconButton aria-label="move" onClick={(e) => handleShowModalMove(e, elem.id)} >
                                            <MoveIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Elimina">

                                        <IconButton aria-label="delete" onClick={(e) => handleShowModalDelete(e, elem.id)} >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                :
                                ""
                            }
                           
                            
                        </>
                        
                    )
                }
                else {
                    return(
                        <>
                            <Tooltip title="Ripristina">
                                <IconButton aria-label="restore" onClick={(e) => handleShowModalRestore(e, elem.id)} >
                                    <RestoreFromTrashIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                    
                }
                
            }
            
        }
      ];

    const [sortModel, setSortModel] = useState(getUserCookie('grid-sort') &&
    getUserCookie('grid-sort').length > 0 &&
    columns.some(x => x.field == getUserCookie('grid-sort')[0]["field"]) ? getUserCookie('grid-sort') : [{
        field: 'last_update',
        sort: 'desc'
    }]);

    const [filteredItems, setFilteredItems] = useState(items)
    const inputFilter = useRef(null)
    const navigate = useNavigate()

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {

            return el.title.toLowerCase().includes(keyword) || el.author.toLowerCase().includes(keyword) ;
        }))
    }


    const showPost = (params) => {
        const element = params.row
        if(!element.deleted) {
            
            if(element.type == "link" || element.type == "attachment")
            {
                //NOTE i must read the post to mark as read
                dispatch(getPost({id: element.id}))
                window.open(element.link, "post_" + params.id);
                
            }
            else if(element.type == "post")
            {
                window.open("/post/" + params.id, "post_" + params.id)
            }
        }
    }
    
    useEffect(()=>{
        setFilteredItems(items)
    },[items])
  
  

    const [selectedRows, setSelectedRows] = useState([]);

    return(
        <>
            <Col className='background-grey'>
                <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
            </Col>
            {
                isLoadingPosts ?
                    <Loader />
                    :  
                    <>
                        <Tooltip title="Segna come non letto">
                            <IconButton aria-label="mark_as_unread" onClick={() => handleShowModalMark(false)}>
                                <MarkUnreadIcon  />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Segna come letto">
                            <IconButton aria-label="mark_as_read" onClick={() => handleShowModalMark(true)}>
                                <MarkReadIcon  />
                            </IconButton>
                        </Tooltip>
                        <Tooltip className='align-right' title="Scarica gli allegati">
                            <IconButton aria-label="download_attachments" onClick={downloadSelectedAttachments}>
                                {/* <Link to={`attachments`}> */}
                                    <DownloadIcon  />
                                {/* </Link> */}
                            </IconButton>
                        </Tooltip>
                        {
                            !readOnly ?
                            <>
                                
                                <Tooltip title="Sposta">
                                    <IconButton aria-label="move" onClick={handleShowModalMove} >
                                        <MoveIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Elimina">

                                    <IconButton aria-label="delete" onClick={handleShowModalDelete}>
                                        <DeleteIcon  />
                                    </IconButton>
                                </Tooltip>
                            </>
                            :
                            ""
                        }
                        
                        
                        <div style={{ height: 650, width: '100%' }}>
                        <DataGridPro
                            sortModel= {sortModel}
                            onSortModelChange={(model) => {
                                setSortModel(model)
                                storeUserCookie('grid-sort',model)
                            }}
                            disableRowSelectionOnClick
                            paginationModel={paginationModel}
                            onPaginationModelChange={(param) => {
                                setPaginationModel(param)
                                storeUserCookie('grid-pagination', param)
                                

                            }}
                            
                            pagination={true}
                            pageSizeOptions={[50,100,200,300]}
                            rowsPerPageOptions={[50, 100, 200,300]}
                            
                            checkboxSelection={true}
                            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                            rows={filteredItems}
                            columns={showDeletedElements ? columns.concat(extendedColumns) : columns}
                            onRowClick={showPost}
                            
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRows(ids);
                            }}
                            getRowClassName={(params) =>
                                params.row.deleted ? 'deleted-row' : ''
                            }
                            isRowSelectable={(dataIndex, selectedRows) => {
                                return !dataIndex.row.deleted 
                            }}
                        />
                        </div>
                    </>   
}
                
                

            
            
            <Modal show={modalState === "modal-delete"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vuoi davvero eliminare {selectedRows.length > 1 ? "i contenuti selezionati" : "il contenuto selezionato"}?
                </Modal.Body>
                <Modal.Footer>
                    <Button className='ui-button' onClick={deleteSelectedPosts}>Elimina</Button>
                </Modal.Footer>
            </Modal>



            <Modal show={modalState === "modal-confirm-move"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vuoi davvero {selectedDestination.restore ? "ripristinare" : "spostare"} {selectedRows.length > 1 ? "i contenuti selezionati" : "il contenuto selezionato"} nella destinazione <br />
              <b>{selectedDestination.folder ? selectedDestination.folder.path + selectedDestination.folder.name : ""}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button className='ui-button' onClick={moveSelectedPosts}>{selectedDestination.restore ? "Ripristina" : "Sposta"}</Button>
                </Modal.Footer>
            </Modal>




            <Modal show={modalState === "modal-mark"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vuoi marcare come {!markAllAsRead ? "non " : ""}letti gli elementi selezionati?
                </Modal.Body>
                <Modal.Footer>
                    <Button className='ui-button' onClick={markSelectedPosts}>Conferma</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalState === "modal-edit-link"} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><span className="green-rectangle" >&nbsp;</span>Modifica link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <input ref={inputLinkName} type="text" className='input-subfolder' maxLength="200" placeholder="Nome"  />

                <input ref={inputLinkUrl} type="text" className='input-subfolder' placeholder="Link"  />
                </Modal.Body>
                <Modal.Footer>
                <Button className='ui-button' onClick={editLink}>Modifica</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalState === "modal-edit-att"} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><span className="green-rectangle" >&nbsp;</span>Modifica nome allegato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <input ref={inputAttName} type="text" className='input-subfolder' maxLength="200" placeholder="Nome"  />
                <DoneIcon sx={{ fontSize: 100 }} /> {currentAttFilename}

                </Modal.Body>
                <Modal.Footer>
                <Button className='ui-button' onClick={editAttachment}>Modifica</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalState === "modal-restore"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Ripristina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='modal-move-body'>
              <Container>
              <Row>
                  <Col>
                        <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                  </Col>
                </Row>
                {
                  isLoadingAllFolders ?
                  <Loader />
                  :
                  <Row>
                    <Col sm={4} xs={6}>
                      <FolderBox
                        item={{
                          id: 0,
                          name: "Cartella originale",
                        }}
                        handleClick={() => { setDestination({
                            id: 0,
                            path: "",
                            name: "originale"
                          }, true)}}
                      />
                    </Col>
                
                    { allAccessibleFoldersFiltered.map((folder,index)=> {
                      const item= {
                        name: folder.name,
                        mine: folder.owner_id === profile.id,
                        popupTitle: folder.path + folder.name

                      }
                      return (
                        <Col sm={4} xs={6} key={"folder_" + index}>
                          <FolderBox
                            item={item}
                            handleClick={() => { setDestination(folder, true)}}
                          />
                        </Col>
                    )
                    })}
                  </Row>
                }
              </Container>
                
              </div>             
            </Modal.Body>
      </Modal>
            <Modal show={modalState === "modal-move"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span className="green-rectangle" >&nbsp;</span>Sposta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                <div className='modal-move-body'>
                    <Container>
                        <Row>
                            <Col>
                                <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                            </Col>
                        </Row>
                        {
                        isLoadingAllFolders ?
                        <Loader />
                        :
                        <Row>
                            { allAccessibleFoldersFiltered.map((folder,index)=> {
                                const item= {
                                    name: folder.name,
                                    mine: 1,
                                    popupTitle: folder.path + folder.name
                                }
                                return (
                                    <Col sm={3} xs={6} key={"folder_" + index}>
                                    <FolderBox
                                        item={item}
                                        handleClick={() => { setDestination(folder)}}
                                    />
                                    </Col>
                                )
                            })}
                        </Row>
                        }
                    </Container>
                    
                </div>             
                </Modal.Body>
          </Modal>
        </>
    )

}

export default PostsGrid