
const TitleBar = ({title,color}) => {
    let barColor = color ?? ""
    return(
        <div className="titlebar">
            <span className="green-rectangle" >&nbsp;</span>
            <strong>{title}</strong>
        </div>
    )

}


export default TitleBar