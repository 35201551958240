import { Container, Row, Col, Button } from 'react-bootstrap';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LIMIT } from '../../../utils/costants';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSharedByMeIcon from '@mui/icons-material/DriveFileMove';
import FolderBox from '../box/folderBox';
import { Tooltip } from '@mui/material';

const FoldersGrid = ({
    items,
    loadMore,
    handleShowModalNewSubfolder,
    handleDeleteElement,
    handleRestoreElement,

    handleMoveElement,
    handleEditElement,
    readOnly = false
}) => {
    const [filteredItems, setFilteredItems] = useState(items)
    const inputFilter = useRef(null)
   

    useEffect(()=>{
        if(items && items.length > 0) {
            let itemsWithPopup = [];
            for (let i = 0; i < items.length; i++) {
                let newItem = { ...items[i], popupTitle: <>Nome: {items[i].name} <br/>{(items[i].owner_name ? "Proprietario:  " + items[i].owner_name : "")}</> };
                itemsWithPopup.push(newItem);
            }
            
            setFilteredItems(itemsWithPopup)
        }
        
    },[items])
  

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {

            return el.name.toLowerCase().includes(keyword);
        }))
    }


    


    return(
        <>
            
            <Container>
                <Row>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                    {/* {
                        !readOnly ?
                            <Col xs={2} lg={1} className='elements-grid-bar' onClick={handleShowModalNewSubfolder}>
                                <Tooltip title={"Nuova cartella"}>
                                    <CreateNewFolderIcon className='grid-bar-icon' />
                                </Tooltip>
                            </Col>
                        :
                        ""
                    } */}
                    
                    
                </Row>
            </Container>
            
            
            <Container className="folder-container">
            { filteredItems && filteredItems.length > 0 ?
                <Row>
                { filteredItems.map((item,index)=>{      
                    return(
                        <Col md={3} xs={6} lg={2} key={index}>
                                <FolderBox
                                    item={item}
                                    handleDelete={handleDeleteElement}
                                    handleEdit={handleEditElement}
                                    handleRestore={handleRestoreElement}
                                    handleMove={handleMoveElement}
                                    readOnly={readOnly}
                                />
                        </Col>
                        
                    )                       
                    
                })}
                </Row>
                :
            <div><p>Nessun elemento da visualizzare</p></div>
        }
            <div className='elements-grid-footer'>
                {
                    loadMore && items && items.length > 0 && items.length % DEFAULT_LIMIT === 0 ?
                    <Button onClick={loadMore} className="ui-button">
                        Carica altri
                    </Button>
                    :
                    ""
                }
                
            </div>
            
            </Container>
        </>
    )

}

export default FoldersGrid