
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAlerts } from '../../../store/actions/specialFolder';
import { removeTags } from '../../../utils/tools';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
const HeaderBottom = () => {
    const dispatch = useDispatch();
    const alerts = useSelector(state=>state.specialFolders.alertsFolder);
    const isAuth = useSelector(state=>state.profile.auth)
    const location = useLocation();

    useEffect(()=>{
        if(isAuth) {
            dispatch(getAlerts())
        }
    }, [location,isAuth])

    const stopMarquee = (e) => {
        if(e.target.stop) {
            e.target.stop()
        }
    }

    const startMarquee = (e) => {
        if(e.target.start) {
            e.target.start()
        }

    }
    return(
        <>
            {
                alerts && alerts.length > 0 ?
                <nav className='bottom-nav'>
                    <Carousel>
                        {
                            alerts.map((item,index)=>(                                    
                                <Carousel.Item key={index}>
                                    <Link className={item.unread ? "red-text carousel-element" : "carousel-element"} key={index} to={"/post/"+ item.id}>{removeTags(item.title)}</Link>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                    
                </nav>
                :
                ""
            }
            
        </>
    )

}


export default HeaderBottom