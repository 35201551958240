import { useDispatch, useSelector} from 'react-redux';
import {Tabs, Tab, Dropdown } from 'react-bootstrap';
import { MAX_ATTACHMENT_SIZE, RIGHTS } from '../../utils/costants';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/translations';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from 'react';
import { getUserCookie, Loader, storeUserCookie } from '../../utils/tools';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import FolderCard from '../../components/elements/folderCard'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import QueueIcon from '@mui/icons-material/Queue';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAllAccessibleFolders, myFolderRights } from '../../store/actions/folders';
import { addAttachment, addPost } from '../../store/actions/posts';
import {getFolderContent, moveFolder, deleteFolder, addFolder, getAllFolderRights, addFolderRight, deleteFolderRight, updateFolderRight} from '../../store/actions/folders'
import LayoutCard from '../../hoc/layoutCard';
import DeleteIcon from '@mui/icons-material/Delete'
import MoveIcon from '@mui/icons-material/MoveDown'
import RightsIcon from '@mui/icons-material/ForkRight'
import FolderIcon from '@mui/icons-material/Folder'
import ContentIcon from '@mui/icons-material/FileUpload'
import { deletePost, movePost } from '../../store/actions/posts';
import { updateFolder } from '../../store/actions/folders';
import { useRef } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PostsGrid from '../elements/postsGrid';
import FoldersGrid from '../elements/grid/foldersGrid';
import FileUploader from '../utils/fileUploader';
import { globalNotification } from '../../store/reducers/notifications';
import RightSelector from '../elements/rightSelector';
import { Tooltip } from '@mui/material';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import AccessDenied from '../elements/accessDenied';
import MultiUploader from '../multiUploader/multiUploader';
import { getSettings } from '../../store/actions/settings';
import FolderBox from '../elements/box/folderBox';

const FolderPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isLoading = useSelector(state=>state.folders.loading);
  const profile = useSelector(state=>state.profile.data)
  const currentFolder = useSelector(state=>state.folders.current);
  const rights = useSelector(state=>state.folders.current.rights);
  const parentRights = useSelector(state=>state.folders.current.parentRights);
  //const users = useSelector(state=>state.users).users;
  //const groups = useSelector(state=>state.groups).groups;
  //const inputName = useRef()

  const paginationModel = getUserCookie('grid-pagination') ? getUserCookie('grid-pagination') : {
    pageSize: 50,
    page: 0,
  };

  const [showDeletedElements, setShowDeletedElements] = useState(false)
  const [restoreMode, setRestoreMode] = useState(false)
  const [editName, setEditName] = useState("")
  const [editDescription, setEditDescription] = useState("")

  const inputSubfolderName = useRef(null)
  // const inputNewAttachmentName = useRef(null)
  const inputNewLinkName = useRef(null)

  const settings = useSelector(state=>state.settings.settings);
  const [maxAttachmentSize, setMaxAttachmentSize] = useState(0)

  useEffect(()=>{
      
      dispatch(getSettings())
  
  }, [])

  useEffect(()=>{
      let max_attachment_size = 0
      for(let i = 0; i < settings.length; i++) {
          if(settings[i]['name'] == 'max_attachment_size') {
              max_attachment_size = settings[i]['value']
          }
      }
      if(!max_attachment_size) {
        max_attachment_size = MAX_ATTACHMENT_SIZE
      }
      setMaxAttachmentSize(max_attachment_size)

  }, [settings])

  const allAccessibleFolders =  useSelector(state=>state.folders).all;

  const [allAccessibleFoldersFiltered, setAllAccessibleFoldersFiltered] = useState([])

  useEffect(()=>{
    setAllAccessibleFoldersFiltered(allAccessibleFolders)
},[allAccessibleFolders])



  const inputFolderFilter = useRef(null)

  const folderFilter = () => {
    const keyword = inputFolderFilter.current.value.toLowerCase()
      setAllAccessibleFoldersFiltered(allAccessibleFolders.filter(function (el) {

            return el.title.toLowerCase().includes(keyword);
        }))
  }


  useEffect(()=>{
       
    dispatch(getSettings())
    
  }, [])



  const isLoadingAllFolders = useSelector(state=>state.folders.loadingAll);

  
  useEffect(()=>{
    if(getUserCookie('grid-pagination')) {
      //reset page count on data grid
      storeUserCookie('grid-pagination',{...getUserCookie('grid-pagination'), page: 0})
    }
    



    dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements})).then( (data) => {
      const folderId = data.payload ? data.payload.id : null
      if(folderId) {
       
        dispatch(myFolderRights({id: data.payload.id, parentId: data.payload.parent_id}))
        dispatch(getAllAccessibleFolders('write_adv'))
        setShowRights(false)


      }

      
    })

    
    

  }, [id])

  

  const updateCurrentFolder = () => {
    dispatch(updateFolder({
      folderId: selectedItem.id,
      data: {
        name: editName,
        description: editDescription
      }
    }))
    .then((e) => {
      if(selectedItem.id != id) {
        dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))
      }
    })
    handleClose()

  }




  const deleteCurrentElement = () => {
    if(selectedItem.type == 'folder') {
      dispatch(deleteFolder({folderId: selectedItem.id})).then((e) => {
        if(selectedItem.id == id) {
          navigate("/folder/" + currentFolder.parent_id,{ replace: true })
        }
        else {
          dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))

        }
      })
    }
    else if(selectedItem.type == 'post') {
      dispatch(deletePost({id: selectedItem.id})).then(() => {
        dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))
      })
    }
    handleClose()

  }
  const { t } = useTranslation()




  const confirmMoveSelectedFolder = (destination,restore = false) => {
    setDestinationFolder(destination)
    setRestoreMode(restore)
    setModalState("modal-confirm-move")
    
  }

  
  const moveCurrentElement = () => {
    if(selectedItem.type == 'folder') {
      dispatch(moveFolder({id: selectedItem.id, folderId: destinationFolder.id, restore: restoreMode})).then((e) => {
        if(selectedItem.id != id) {
          dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))

        }
      })
    }
    else if(selectedItem.type == 'post') {
      dispatch(movePost({id: selectedItem.id, folderId: destinationFolder.id, restore: restoreMode})).then(() => {
        dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))
      })
    }
    setDestinationFolder(null)
    handleClose()
  }



  const handleEditElement = (e, currentElement) => {
    if(currentElement.type == 'folder') {
      //navigate("/folder/" + currentElement.id)
      //console.log(currentElement)
      setEditName(currentElement.name)
      setEditDescription(currentElement.description)

      setSelectedItem(currentElement)
      setModalState("modal-edit")
    }
    else if(currentElement.type == 'post') {
      navigate("/post/" + currentElement.id + "/edit")
    }
  }

  const handleShowModalMove = (e, currentElement) => {
    setSelectedItem(currentElement)
    setAllAccessibleFoldersFiltered(allAccessibleFolders)
    setModalState("modal-move")
  }

  const handleShowModalNewLink = (e, currentElement) => {
    setModalState("modal-new-link")
  }

  const handleShowModalNewAtt = (e, currentElement) => {
    setModalState("modal-new-attachment")
  }
  

  const handleShowModalRestore = (e, currentElement) => {
    setSelectedItem(currentElement)
    setAllAccessibleFoldersFiltered(allAccessibleFolders)

    setModalState("modal-restore")
  }
  
  const reloadRights = () => {
    dispatch(getAllFolderRights({folderId: id}))

  }


  const addRight = (data) => {
      dispatch(addFolderRight({folderId: id,data: data}), [dispatch]).then(() =>  {
        reloadRights()
      })
    
  }

  const deleteRight = (data) => {
    dispatch(deleteFolderRight({folderId: id,rightId: data.right_id}), [dispatch]).then(() => {
      reloadRights()
    })
  }

  const updateRight = (data) => {
    dispatch(updateFolderRight({folderId: id,rightId: data.right_id, right: data.right}), [dispatch]).then(() => {
      reloadRights()
    })
  }


  

  const publishPost = (title, content, type, link = "", file = null) => {
    dispatch(addPost({title: title, content: content, link: link, folder_id: id,type: type})).then( (data) => {
        setNewLink("")
        setNewLinkName("")
        if(file) {
          dispatch(globalNotification({
            status: "success",
            message: "wait_for_load"
          }))
          dispatch(addAttachment({id: data.payload.id, file: file})).then(() => {
            setNewAttFile(null)
            setNewAttName("")
            dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))
          })
        }
        else {
          // dispatch(globalNotification({
          //     status: "success",
          //     message: "create_post_success"
          // }))
          dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))
        }      
    })
}

  const publishLink = () => {
    if(!newLinkName) {
      dispatch(globalNotification({
        status: "error",
        message: "insert_link_name"
      }))
      return;
    }

    if(!newLink) {
      dispatch(globalNotification({
        status: "error",
        message: "link_is_required"
      }))
      return;
    }
    publishPost(newLinkName, "", 'link', newLink)
    handleClose()
  }


  const publishAttachment = () => {
    if(!newAttFile) {
      dispatch(globalNotification({
        status: "error",
        message: "insert_attachment"
      }))
      return;
    }

    if(!newAttName) {
      dispatch(globalNotification({
        status: "error",
        message: "insert_attachment_name"
      }))
      return;
    }

   
    publishPost(newAttName, newAttFile.name, 'attachment', "", newAttFile)
    handleClose()
  }

  const addSubfolder = () => {
    if(subFolderName == "") {
      dispatch(globalNotification({
        status: "error",
        message: "insert_folder_name"
      }))
      return
    }
    dispatch(addFolder({name: subFolderName, parentId: id, description: subFolderDescription})).then(() => {
      dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))

    })
    handleClose()
  }
  const [showRights, setShowRights] = useState(false)

  const toggleHandleRights = () => {
    if(!showRights) {
      dispatch(getAllFolderRights({folderId: id}))

    }
    
    setShowRights(!showRights)
  }
  
  const toggleShowDeletedElements = () => {
    dispatch(getFolderContent({folderId: id, showDeletedElements: !showDeletedElements}))

    setShowDeletedElements(!showDeletedElements)


  }

  const handleReload = () => {
    dispatch(getAllAccessibleFolders('write_adv'))
    dispatch(getFolderContent({folderId: id, showDeletedElements: showDeletedElements}))

  }



  const [modalState, setModalState] = useState("close")
  const [subFolderName, setSubfolderName] = useState("")
  const [subFolderDescription, setSubfolderDescription] = useState("")
  const [newLink, setNewLink] = useState("")
  const [newLinkName, setNewLinkName] = useState("")
  const [newAttName, setNewAttName] = useState("")
  const [newAttFile, setNewAttFile] = useState(null)

  const [key, setKey] = useState('read');

  const [selectedItem, setSelectedItem] = useState({id: id, type: 'folder'});
  const [destinationFolder, setDestinationFolder] = useState(null);

  const handleShowModalNewSubfolder = () => {
   setModalState("modal-subfolder")
  }

  const handleShowModalNewContent = () => {
    setModalState("modal-new-content")
   }

   useEffect(() => {
    if (modalState == "modal-subfolder") {
      inputSubfolderName.current.focus()
    }
    // else if (modalState == "modal-new-attachment") {
    //   setTimeout(() => {
    //     inputNewAttachmentName.current.focus()
    //   }, 500)
      
    // }
    else if (modalState == "modal-new-link") {
      setTimeout(() => {
        inputNewLinkName.current.focus()
      }, 500)
    }
  }, [modalState]);

  const createNewPost = () => {
    navigate("/folder/" + id + "/new-post")
  }

  const handleShowModalDelete = (e, currentElement) => {
    setSelectedItem(currentElement)
    setModalState("modal-delete")
  }

  const handleShowModalConfirmProtect = (e, currentElement) => {
    setSelectedItem(currentElement)
    setModalState("modal-lock")
  }

  const toggleLock = () => {
    dispatch(updateFolder(
      {
        folderId: id,
        data: {
          locked: currentFolder.locked ? 0 : 1
        }
      }))
    handleClose()
  }

  // const handleShowModalEdit = (e, currentElement) => {
  //   console.log(currentElement)
  //   setSelectedItem(currentElement)
  //   setModalState("modal-edit")
  // }



  const handleClose = () => {
   setModalState("close")
  }
    return(
      <>
        { isLoading ?
          <Loader />
        :
        (
          !currentFolder.id ?
          <AccessDenied />
          :
          <Container>
            <Row>
                <Col xs={12}>
                  <Row>
                    <Col sm={8} className='d-none d-sm-block'></Col>
                    <Col sm={4} className='folder-operations'>
                      {
                        rights["write"] ?
                          <Tooltip title="Nuovo contenuto">

                            <Button className="dropdown-button">

                              <div className="align-right" onClick={handleShowModalNewContent}>
                                <QueueIcon />
                              </div>
                            </Button>
                          </Tooltip>
                        :
                          ""
                      }
                      {
                        rights["write_adv"] ?
                          <Tooltip title="Nuova cartella">

                            <Button className="dropdown-button">
                                <div className="align-right" onClick={handleShowModalNewSubfolder}>
                                  <CreateNewFolderIcon />
                                </div>

                            </Button>
                          </Tooltip>
                            
                        :
                          ""
                      }
                      
                      {
                        !profile.admin && currentFolder.system?
                        ""
                        :
                        (!rights["write_adv"] && !parentRights["write_adv"] ?
                        ""
                        :
                        <Dropdown className="operation-dropdown" drop={'start'}>
                          <Dropdown.Toggle variant="success" className='dropdown-button'>
                              <SettingsIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                              
                              
                                
                              {
                                currentFolder.locked ?
                                ""
                                :
                                <>
                                {
                                  !currentFolder.system && parentRights["write_adv"] ?
                                  <Dropdown.Item>
                                    <div className="operation-card highlight-element" onClick={(e) => handleEditElement(e,{
                                      id: id,
                                      type: 'folder',
                                      name: currentFolder.name,
                                      description: currentFolder.description                                              })}>
                                      <EditIcon />MODIFICA
                                    </div>
                                  </Dropdown.Item>
                                  :
                                  ""
                                }
                                {
                                  !currentFolder.system && parentRights["write_adv"] ?
                                  <Dropdown.Item>
                                    <div className="operation-card highlight-element" onClick={(e) => handleShowModalMove(e,{
                                      id: id,
                                      type: 'folder',
                                      //path: currentFolder.path + "/" + currentFolder.name
                                    })}>
                                      <MoveIcon />SPOSTA
                                    </div>
                                  </Dropdown.Item>
                                  :
                                  ""
                                }
                                
                                {
                                  !currentFolder.system && parentRights["write_adv"] ?
                                  <Dropdown.Item>
                                    <div className="operation-card highlight-element" onClick={(e) => handleShowModalDelete(e,{
                                      id: id,
                                      type: 'folder'
                                    })} >
                                      <DeleteIcon />ELIMINA
                                    </div>
                                  </Dropdown.Item>
                                  :
                                  ""
                                }
                                </>
                              }
                              {
                                profile.admin || profile.id == currentFolder.owner_id || rights["permission_management"] ?
                                  <Dropdown.Item>
                                    <div className="operation-card highlight-element" onClick={(e) => handleShowModalConfirmProtect(e,{
                                    id: id,
                                    type: 'folder'
                                    //path: currentFolder.path + "/" + currentFolder.name
                                  })}>
                                      {
                                        currentFolder.locked ?
                                        <>
                                          <LockOpenIcon /> SBLOCCA CARTELLA
                                        </>
                                        :
                                        <>
                                          <LockIcon /> PROTEGGI CARTELLA
                                        </>
                                      }
                                    </div>
                                  </Dropdown.Item>
                                :
                                  ""
                              }
                             
                              {
                                rights["permission_management"] ?
                                <Dropdown.Item>
                                  <div className={showRights ? "ui-button operation-card highlight-element"  : "operation-card highlight-element"} onClick={toggleHandleRights}>
                                      <RightsIcon />GESTISCI PERMESSI

                                  </div>
                                </Dropdown.Item>
                                :
                                ""
                              }
                               {
                                  parentRights["read"] || id == profile.rootFolder  ?
                                  <Dropdown.Item>
                                    <div className={showDeletedElements ? "ui-button operation-card highlight-element" : "operation-card highlight-element"}  onClick={toggleShowDeletedElements} >
                                      <FolderDeleteIcon />VISUALIZZA ELIMINATI
                                    </div>
                                  </Dropdown.Item>
                                  :
                                  ""
                                }
                                
                                
                              
                              
                              
                          </Dropdown.Menu>
                      </Dropdown>)
                      }

                    </Col>
                  </Row>
                  
                  <FolderCard
                    folder={currentFolder}
                    tooltip={<>Nome: {currentFolder.name} <br/>{(currentFolder.owner_name ? "Proprietario:  " + currentFolder.owner_name : "")}</>}

                  />
                            
                              
                </Col>
            </Row>
            <Row>
                <Col>
                {
                  showRights ? 
              
                  <LayoutCard
                    title={"PERMESSI"}
                  >
                    {currentFolder.loadingRights ?
                            <Loader />
                            :
                            <>
                          
                                
                              <h3>Pubblico</h3>
                              <Container>
                                {
                                  currentFolder.allRights['pub'].length > 0 ?
                                  <RightSelector disabled={!profile.admin} type={'pub'} element={currentFolder.allRights['pub'][0]} onUpdate={updateRight} onRemove={deleteRight} />

                                  :
                                  (
                                    profile.admin ?
                                    <RightSelector type={'pub'} onAdd={addRight} />
                                    :
                                    ""
                                  )


                                }
                              </Container>
                              <hr></hr>
                              <h3>Gruppi</h3>
                              <Container>
                                {
                                  currentFolder.allRights['grp'] ? 
                                  currentFolder.allRights['grp'].map((item,index)=>(
                                    <RightSelector disabled={!profile.admin} key={index} type={'grp'} element={item} onUpdate={updateRight} onRemove={deleteRight} />
                                  ))
                                  
                                  :
                                  ""

                                }
                                {
                                  profile.admin ?
                                  <RightSelector type={'grp'} onAdd={addRight} />
                                  :
                                  ""

                                }

                              </Container> 
                              <hr></hr>
                            
                                                
                              
                              <h3>Utenti</h3>
                              <Container>
                              {
                                  currentFolder.allRights['usr'] ? 
                                  currentFolder.allRights['usr'].map((item,index)=>(
                                    <RightSelector key={index} type={'usr'} element={item} onUpdate={updateRight} onRemove={deleteRight} />
                                  ))
                                  
                                  :
                                  ""

                                }
                                <RightSelector type={'usr'} onAdd={addRight} />

                              </Container>

                              
                            </>
                          }


                   

                  </LayoutCard>
                  :
                  <>
                  {/* <div>
                    <input checked={showDeletedElements} type="checkbox" id="show_deleted" onChange={toggleShowDeletedElements}/>
                    <label htmlFor="show_deleted"> Visualizza contenuti eliminati</label><br></br>
                  </div> */}
                  
                  {
                    currentFolder.subfolders ?
                    <LayoutCard title={"SOTTOCARTELLE"} variant="white" actionButton={rights["write_adv"] ? {
                      action: handleShowModalNewSubfolder,
                      text: "Nuova cartella"
                    } : null}>
                      <FoldersGrid
                        items={currentFolder.subfolders}
                        handleDeleteElement={handleShowModalDelete}
                        handleMoveElement={handleShowModalMove}
                        handleEditElement={handleEditElement}
                        handleRestoreElement={handleShowModalRestore}
                        readOnly={!rights["write_adv"]}


                      />
                    </LayoutCard>
                    : 
                    ""
                  }
                  {
                    currentFolder.posts ?
                    <LayoutCard title={"CONTENUTI"} variant="white" actionButton={rights["write"] ? {
                      action: handleShowModalNewContent,
                      text: "Nuovo contenuto"
                    } : null}>
                      <PostsGrid
                        rowCount={currentFolder.postCounter}
                        items={currentFolder ? currentFolder.posts : []}
                        handleReload={handleReload}
                        readOnly={!rights["write"]}
                        showDeletedElements={showDeletedElements}
                        //getPosts={getPosts}
                      />
                    </LayoutCard>

                    : 
                    ""
                  }
                  
                  
                  
                  </>
                  
                }
                  
                </Col>
            </Row>
          </Container>
        )
        }
        <Modal show={modalState === "modal-delete"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero eliminare {selectedItem.type == 'folder' ? "la cartella selezionata e tutto il suo contenuto?" : "il messaggio selezionato?"}
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={deleteCurrentElement}>Elimina</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalState === "modal-lock"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            currentFolder.locked ?
            "Vuoi sbloccare la cartella protetta?"
            :
            "Vuoi davvero rendere la cartella protetta? Nessuna modifica sarà più possibile"
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={toggleLock}>
          {
            currentFolder.locked ?
            "Sblocca"
            :
            "Blocca"
          }
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalState === "modal-confirm-move"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Conferma operazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero {restoreMode ? "ripristinare" : "spostare" } l'elemento selezionato nella destinazione <b>{destinationFolder ? destinationFolder.path + destinationFolder.name : ""}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={moveCurrentElement}>Sposta</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalState === "modal-move"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Sposta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='modal-move-body'>
              <Container>
              <Row>
                  <Col>
                        <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                  </Col>
                </Row>
                {
                  isLoadingAllFolders ?
                  <Loader />
                  :
                  <Row>
                    <Col sm={3} xs={6} >
                      <FolderBox
                        item={{
                          id: profile.rootFolder,
                          name: 'Home',
                          popupTitle: "Home"
                        }}
                        handleClick={() => { confirmMoveSelectedFolder({ 
                          id: profile.rootFolder,
                          path: "",
                          name: "Home"
                        })}}
                      />
                    </Col>
                    { allAccessibleFoldersFiltered.map((folder,index)=> {
                      const item= {
                        name: folder.name,
                        mine: folder.owner_id === profile.id,
                        popupTitle: folder.path + folder.name
                      }
                      return (
                        <Col sm={3} xs={6} key={"folder_" + index}>
                          <FolderBox
                            item={item}
                            handleClick={() => { confirmMoveSelectedFolder(folder)}}
                          />
                        </Col>
                    )
                    })}
                  </Row>
                }
              </Container>
                
              </div>             
            </Modal.Body>
          </Modal>

        <Modal show={modalState === "modal-new-link"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuovo link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" ref={inputNewLinkName} className='input-subfolder' maxLength="200" placeholder="Nome" onChange={event => setNewLinkName(event.target.value)}  />

          <input type="text" className='input-subfolder' placeholder="Link" onChange={event => setNewLink(event.target.value)}  />
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={publishLink}>Aggiungi</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalState === "modal-new-attachment"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Aggiungi allegato/i</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {/*<input type="text" ref={inputNewAttachmentName} className='input-subfolder' maxLength="200" placeholder="Nome" onChange={event => setNewAttName(event.target.value)}  />
          
            <FileUploader
                accept={"*"}
                onFileSelectSuccess={(file) => {
                  setNewAttFile(file)
                  if(newAttName == "") {
                    setNewAttName(file.name)

                  }
                }}
                onFileSelectError={({ error }) => { dispatch(globalNotification({
                  status: "error",
                  message: error
                })) }}
                multiple={false}
                maxSize={maxAttachmentSize}
              >
                {
                  newAttFile ?
                  <>
                  <DoneIcon sx={{ fontSize: 100 }} />
                  {newAttFile.name}
                  </>
                  :
                  <FileUploadIcon sx={{ fontSize: 100 }} />

                }

            </FileUploader> */}

              
          
        <MultiUploader
          folderId={id}
          onUpload={() => {handleReload(paginationModel.page * paginationModel.pageSize, paginationModel.pageSize); handleClose();}}
        >

        </MultiUploader>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className='ui-button' onClick={handleClose}>Chiudi</Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={modalState === "modal-subfolder"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuova Cartella</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" ref={inputSubfolderName} className='input-subfolder' maxLength="200" placeholder="Nome" onChange={event => setSubfolderName(event.target.value)}  />
          <textarea className='input-editfolder' placeholder="Descrizione" onChange={event => setSubfolderDescription(event.target.value)} ></textarea>

        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={addSubfolder}>Aggiungi</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={modalState === "modal-new-content"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuovo contenuto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <p>Scegli il tipo di contenuto da creare</p>
          <Row>
              <Col md={4}>
                <div className={"element-card highlight-element dropdown-container"}>
                    <div onClick={createNewPost} >
                        <span className='green-rectangle' >&nbsp;</span><strong>POST</strong>
                        <div className="element-card-content green-content">
                        <PostIcon sx={{ fontSize: 100 }}  />
                        </div>
                        
                    </div>
                   
                </div>
              </Col>
              <Col md={4}>
                <div className={"element-card highlight-element dropdown-container"}>
                    <div onClick={handleShowModalNewLink} >
                        <span className='green-rectangle' >&nbsp;</span><strong>LINK</strong>
                        <div className="element-card-content green-content">
                        <LinkIcon sx={{ fontSize: 100 }}  />
                        </div>
                        
                    </div>
                   
                </div>
              </Col>
              <Col md={4}>
                <div className={"element-card highlight-element dropdown-container"}>
                  <div onClick={handleShowModalNewAtt} >

                    <span className='green-rectangle' >&nbsp;</span><strong>ALLEGATO</strong>
                    <div className="element-card-content green-content no-padding">
                        <AttachmentIcon sx={{ fontSize: 100 }}  />

                    
                    </div>
                  </div>

                        
                   
                </div>
              </Col>
                              
            </Row>
                      
          </Container>
        </Modal.Body>
        
      </Modal>



      <Modal show={modalState === "modal-edit"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Modifica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input defaultValue={editName} type="text" className='input-editfolder' maxLength="200" placeholder="Nome" onChange={event => setEditName(event.target.value)}  />
          <textarea defaultValue={editDescription} className='input-editfolder' placeholder="Descrizione" onChange={event => setEditDescription(event.target.value)} ></textarea>

        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={updateCurrentFolder}>Modifica</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalState === "modal-restore"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Ripristina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='modal-move-body'>
              <Container>
              <Row>
                  <Col>
                        <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                  </Col>
                </Row>
                {
                  isLoadingAllFolders ?
                  <Loader />
                  :
                  <Row>
                    <Col sm={3} xs={6}>
                      <FolderBox
                        item={{
                          id: 0,
                          name: "Cartella originale",
                          type: "folder"
                        }}
                        handleClick={() => { confirmMoveSelectedFolder({
                          id: 0,
                          path: "",
                          name: "originale"
                        }, true)}}
                      />
                    </Col>
                    { allAccessibleFoldersFiltered.map((folder,index)=> {
                      const item= {
                        name: folder.name,
                        mine: folder.owner_id === profile.id,
                        popupTitle: folder.path + folder.name

                      }
                      return (
                        <Col sm={4} xs={6} key={"folder_" + index}>
                          <FolderBox
                            item={item}
                            handleClick={() => { confirmMoveSelectedFolder(folder, true)}}
                          />
                        </Col>
                    )
                    })}
                  </Row>
                }
              </Container>
                
              </div>             
            </Modal.Body>
      </Modal>
      </>
    )

}


export default  FolderPage