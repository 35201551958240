
import ApplicationIco from '@mui/icons-material/SettingsApplications'

import DeviceUnknown from '@mui/icons-material/DeviceUnknown'

import { Link } from 'react-router-dom';

const AppIcon = ({link,name, Icon, image = null}) => {

    let iconComponent;
    if(image != null) {
        iconComponent = <img className="app-icon-image" src={image}></img>

    }
    else {
        iconComponent = <Icon sx={{ fontSize: 40 }}  />

    }
    let iconContent = ""
    if(link.startsWith("http")) {
        iconContent = <a target="_blank" href={link}>
            <div className='app-icon-content'>
                {iconComponent}
                <p>{name}</p>
            </div>       
        </a> 
    }
    else {
        iconContent = <Link to={link}>
            <div className='app-icon-content'>
                {iconComponent}
                <p>{name}</p>
            </div>       
        </Link>
    }
    return(
        <div className='app-icon highlight-element'>
            {iconContent}                      
        </div>
    )

}


export default  AppIcon