

import { Container, Row, Col, Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../store/actions/users';
import { useEffect, useRef, useState } from 'react'
import LayoutCard from '../../hoc/layoutCard';
import { advancedSearch, resetSearch } from '../../store/actions/search';
import DatePicker from "react-datepicker";
import { itIT } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import 'react-datepicker/dist/react-datepicker.css'
import { Loader } from '../../utils/tools';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/es';
import SimpleSearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import FolderSearchIcon from '@mui/icons-material/Pageview';
import FolderAutocompleter from '../elements/autocompleter/folderAutocompleter';
import { getUserCookie, storeUserCookie } from '../../utils/tools';
import { deletePost, getPost, movePost } from '../../store/actions/posts';
import {Tooltip}  from '@mui/material';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import { formatDate } from '../../utils/tools';
import { WindowSharp } from '@mui/icons-material';

registerLocale('it', it)

const AdvancedSearch = () => {
    const navigate=useNavigate()

    const inputTitle = useRef(null)
    const inputContent = useRef(null)
    const inputAuthor = useRef(null)
    const inputMinAttSize = useRef(null)
    const inputMaxAttSize = useRef(null)
    const inputAttContent = useRef(null)
    const inputMinPublishDate = useRef(null)
    const inputMaxPublishDate = useRef(null)
    const subFolderSearch = useRef(null)
    const [targetFolder, setTargetFolder] = useState(0)


   


    const showPost = (params) => {
        const element = params.row
        //console.log(element)
        if(!element.deleted) {
            
            if(element.type == "link" || element.type == "attachment")
            {
                //NOTE i must read the post to mark as read
                dispatch(getPost({id: element.id}))
                window.open(element.link, "post_" + params.id);
                
            }
            else if(element.type == "post")
            {
                window.open("/post/" + params.id, "post_" + params.id)
            }
        }
    }
    const [page, setPage] = useState(0)
    const [currentSearch, setCurrentSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const items = useSelector(state=>state.search).results
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(resetSearch())
    },[])


    const columns = [
        // {
        //     field: 'unread',
        //     headerName: '●',
        //     filterable: false,
        //     sortable: true,
        //     width: 12,
        //     cellClassName: 'centered-text',

        //     renderCell: (param) => {
        //         const elem = param.row

        //         return (
        //             <>
        //             {
        //                 elem["unread"] ?
        //                 <Tooltip title={"Da leggere"}><div className="red-dot"></div></Tooltip>
        //                 :
        //                 (
        //                     elem["new_comment"] ?
        //                     <Tooltip title={"Nuovo commento aggiunto"}><div className="green-dot"></div></Tooltip>
        //                     :
        //                     ""
        //                 )
                        
        //             }
        //             </>
                    
        //         )
        //     }
            
        // },
        {
            field: 'author',
            headerName: 'Autore',
            minWidth: 200,
            flex:5,
            renderCell: (param) => {
                return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
        },
        { 
            field: 'title',
            headerName: 'Oggetto',
            minWidth: 400,
            flex:10,
            renderCell: (param) => {
                const row = param.row
                switch(row.type) {
                    case 'post':
                        return <Tooltip title={row.title}><span>{row.title}</span></Tooltip>
                    case 'attachment':
                        return <Tooltip title={row.content}><span>{row.title}</span></Tooltip>
                    case 'link':
                        return <Tooltip title={row.link}><span>{row.title}</span></Tooltip>
                    
                }
            }
        },
        { 
            field: 'path',
            headerName: 'Path',
            minWidth: 400,
            flex:8,
            renderCell: (param) => {
                return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
            }
            
        },
        {
            field: 'last_update', headerName: 'Data', width: 200,
            renderCell: (param) => {
                const formattedDate = formatDate(param.value)
                return <Tooltip title={formattedDate}><span>{formattedDate}</span></Tooltip>
            }

        },       
        {
            field: 'type',
            headerName: 'Tipologia',
            filterable: false,
            sortable: false,
            width: 80,
            renderCell: (param) => {
                switch(param.value) {
                    case 'post':
                        return <Tooltip title="Post"><PostIcon sx={{ fontSize: 20 }} /></Tooltip>
                    case 'link':
                        return <Tooltip title="Link"><LinkIcon  sx={{ fontSize: 20 }}  /></Tooltip>
                    case 'attachment':
                        return <Tooltip title="Allegato"><AttachmentIcon sx={{ fontSize: 20 }}  /></Tooltip>
                }
            }
        }
    ];

    const [sortModel, setSortModel] = useState(getUserCookie('grid-sort') &&
    getUserCookie('grid-sort').length > 0 &&
    columns.some(x => x.field == getUserCookie('grid-sort')[0]["field"]) ? getUserCookie('grid-sort') : [{
        field: 'last_update',
        sort: 'desc'
    }]);

    //reset page count on data grid
    const [paginationModel, setPaginationModel] = useState(getUserCookie('grid-pagination') ? {...getUserCookie('grid-pagination'), page: 0} : {
        pageSize: 50,
        page: 0,
    });

    useEffect(()=>{
          setFilteredItems(items)
      },[items])
    

    const [filteredItems, setFilteredItems] = useState([])
    const inputFilter = useRef(null)

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {
            return el.title.toLowerCase().includes(keyword) || el.author.toLowerCase().includes(keyword) ;
        }))
    }

    
    const refreshResults = () => {
        setIsLoading(true)
        let currentParams = {
            title: inputTitle.current.value,
            content: inputContent.current.value,
            author: inputAuthor.current.value,
            folder_id: targetFolder ?? "",
            min_att_size: inputMinAttSize.current.value,
            max_att_size: inputMaxAttSize.current.value,
            min_publish_date: inputMinPublishDate.current.value,
            max_publish_date: inputMaxPublishDate.current.value,
            att_content: inputAttContent.current.value,
            subfolder_search: subFolderSearch.current.checked ? 1 : 0

        }

        const isEmpty = Object.values(currentParams).every(x => x === null || x === '' || x === 0);
        if(isEmpty) {
            dispatch(resetSearch())
            setIsLoading(false)

            return
        }
        dispatch(advancedSearch({data: currentParams, page: 0})).then(() => {
            setCurrentSearch(currentParams)
            setPage(0)
            setIsLoading(false)
        })
        


    }

    // const loadMore = () => {
    //     setIsLoading(true)
    //     const currentPage = page + 1 
    //     dispatch(advancedSearch({data: currentSearch, page: currentPage})).then(() =>{
    //         setPage(currentPage)
    //         setIsLoading(false)
    //     })


    // }


    useEffect(() => {
        function handleKeyDown(e) {
            if(e.keyCode == 13 && !isLoading) {
                refreshResults()
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
    return(
        <>
        <LayoutCard
            title={"RICERCA AVANZATA"}
        >
            <Container>
                <Row>
                    <Col md={6}>
                        <b>Contenuto</b><br></br>
                        <input className="search-input" ref={inputTitle} type="text" placeholder="Cerca nel titolo" /><br></br>
                        <input className="search-input" ref={inputContent} type="text" placeholder="Cerca nel contenuto" /><br></br><br></br>

                        <b>Autore</b><br></br>
                        <input className="search-input" ref={inputAuthor} type="text" placeholder="Autore" /><br></br>

                        <b>Cartella</b><br></br>
                        <FolderAutocompleter setSelected={setTargetFolder} />

                    </Col>
                    <Col md={6}>
                        <b>Allegati</b> <small>(la ricerca per contenuto non considera gli allegati non indicizzabili: immagini, pdf/a, ecc...)</small><br></br>
                        <input className="search-input" ref={inputAttContent} type="text" placeholder="Cerca nel contenuto del file" /><br></br>
                        <input className="search-input" ref={inputMinAttSize} type="text" placeholder="Dimensione minima (in byte)" /><br></br>
                        <input className="search-input" ref={inputMaxAttSize} type="text" placeholder="Dimensione massima (in byte)" /><br></br><br></br>

                        <b>Intervallo di pubblicazione</b><br></br>
                        <input className="search-input" ref={inputMinPublishDate} type="date" placeholder="Data inizio" /><br></br>
                      
                        <input className="search-input" ref={inputMaxPublishDate} type="date" placeholder="Data fine" /><br></br>

                    </Col>
                </Row>
                <Row>
                    <Col sm={{span: 6 }}>
                    <input id="subfolder-search" defaultChecked={false} type="checkbox" ref={subFolderSearch} /> <label htmlFor="subfolder-search">Ricerca nelle sottocartelle</label>

                    </Col>
                    <Col sm={{span: 2, offset: 4 }}>
                        <Button className="ui-button btn-block" onClick={refreshResults}>Cerca</Button>
                    </Col>
                </Row>
                
                </Container>
        </LayoutCard>
        <Container>
            <Row>
                    <Col sm={{span: 3, offset: 6}}>
                        <Button className="grey-button btn-block" onClick={() => {navigate("/folder-search")}}>
                            <FolderSearchIcon/> Cerca cartelle
                        </Button>

                    </Col>
                    <Col sm={{span: 3 }}>
                        <Button className="green-button btn-block" onClick={() => {navigate("/search")}}>
                            <SimpleSearchIcon/> Ricerca base
                        </Button>

                    </Col>
                    
                </Row>
                
        </Container>
        <LayoutCard
            title={"RISULTATI"}
            variant="white"
        >
            {
                isLoading ? 
                <Loader />
                :
                <>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                    <div style={{ height: 650, width: '100%' }}>
                        <DataGridPro
                            sortModel= {sortModel}
                            onSortModelChange={(model) => {
                                setSortModel(model)
                                storeUserCookie('grid-sort',model)
                            }}
                            disableRowSelectionOnClick
                            paginationModel={paginationModel}
                            onPaginationModelChange={(param) => {
                                setPaginationModel(param)
                                storeUserCookie('grid-pagination', param)

                            }}
                            pagination={true}
                            pageSizeOptions={[50,100,200,300]}
                            rowsPerPageOptions={[50, 100, 200,300]}
                            
                            checkboxSelection={false}
                            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                            rows={filteredItems}
                            columns={columns}
                            onRowClick={showPost}
                            
                            handleRowClick
                            getRowClassName={(params) =>
                                params.row.deleted ? 'deleted-row' : ''
                            }
                            isRowSelectable={(dataIndex, selectedRows) => {
                                return !dataIndex.row.deleted 
                            }}
                        />
                    </div>
                </>
            }
            
        </LayoutCard>
       
    </>
  )

}


export default  AdvancedSearch