import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getMenu } from '../../store/actions/menuItems';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import { getLDAPGroups, getMapping, getGroups, addMapping, deleteMapping } from '../../store/actions/groups';
import LdapGroupAutocompleter from '../elements/autocompleter/ldapGroupAutocompleter';
import GroupAutocompleter from '../elements/autocompleter/groupAutocompleter';
const EditMapping = () => {

    const [ldapGroup, setLDAPGroup] = useState("")
    const [group, setGroup] = useState(0)


    const groups = useSelector(state => state.groups.groups)

    const mapping = useSelector(state => state.groups.mapping)
    //const [currentMapping, setCurrentMapping] = useState(mapping)

    const addItem = (element) => {
      if(ldapGroup != "" && group > 0) {
        dispatch(addMapping({
            source_group: ldapGroup,
            group_id: group
        })).then(() => {
            dispatch(getMapping())
        })
      }
    }

 
    const removeItem = (index) => {
        const map = mapping[index]
        dispatch(deleteMapping({
            id: map.id
        })).then(() => {
            dispatch(getMapping())

            // setCurrentMapping((mapping) => {
            //     let ret = []
            //     for (let i in mapping) {
            //         if(index != i) {     
            //             ret.push(mapping[i])
            //         }
            //     }
            //     return ret
            // });
        })
        
    }
    const dispatch = useDispatch();

    useEffect(()=>{
       
            dispatch(getMapping())
            dispatch(getGroups())

        
        
    }, [])

//     useEffect(()=>{
//         setCurrentMapping(mapping)   
    
// }, [mapping])


    return(
        <>
            <h3>Mapping dei gruppi</h3>
            <Container className='admin-options'>
                <Row>
                    <Col xs={4}>
                        <b>Gruppo LDAP</b>
                
                    </Col>
                    <Col xs={4}>
                        <b>Gruppo Intranet</b>
                    </Col>
                    <Col xs={4}>

                    </Col>
                </Row> 
            { 
                mapping && mapping.length > 0 ? 
                mapping.map((item,index)=> {
                    let group = groups.find(el => el["id"] == item.group_id)
                    return (
                        <Row key={index}>
                            <Col xs={4}>
                                {item.source_group}
                        
                            </Col>
                            <Col xs={4}>
                                <>
                                    { group ? group.name : "" }
                                </>
                                

                            </Col>
                            <Col xs={4}>
                                <DeleteIcon className='ui-icon' onClick={() => removeItem(index)} />

                            </Col>
                        </Row> 
                    )
                })

                :
                    ""
            }
            <Row className='menu-item'>
                <Col xs={4}>
                    <LdapGroupAutocompleter setSelected={setLDAPGroup} />
                </Col>
                <Col xs={4}>
                    <GroupAutocompleter setSelected={setGroup} />
                </Col>
                <Col xs={4}>
                    <AddIcon className='ui-icon' onClick={addItem} />
                </Col>
            </Row>
            </Container>
            

            
        </>
    )
}

export default EditMapping;