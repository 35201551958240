import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { getAuthHeader } from "../../utils/tools";
import { Link } from "react-router-dom";
export default function RssViewer() {
    const [items, setItems] = useState([]);
    const getRss = async () => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rss`,getAuthHeader());




        setItems(res.data);
    };

    const lastFeed = items ? items[0] : null
    useEffect(() => {
        getRss()
    }, [])
    return (
        <div>
            {lastFeed ? 
                
                    <div className="rss-feed-container">
                        <a href={lastFeed.link} target="_blank">
                            <img src={lastFeed.image} className="rss-feed-image"></img>
                            <div className="rss-feed-title">
                                    <b>{lastFeed.title}</b>
                            </div>
                        </a>
                    </div>
                    :
                    ""
                
            
            }
        </div>
    );
}