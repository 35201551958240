
import PanToolIcon from '@mui/icons-material/PanTool';
import { Link } from 'react-router-dom';
const AccessDenied = () => {

  return(
    <div className="error-content">
      <PanToolIcon sx={{ fontSize: 200 }}/>
      <br></br>
      <br></br>
      <h3>Non si dispone delle autorizzazioni necessarie per accedere alla pagina.</h3>
      <Link to={"/"}>Torna alla home</Link>
    </div>
  )

}


export default  AccessDenied