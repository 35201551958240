import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader } from '../../utils/tools';
import axios from 'axios'
import { ALERT_FOLDER, NOTICE_FOLDER, COMMON_FOLDER } from '../../utils/costants';




axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getCommonFolder = createAsyncThunk(
    'specialFolders/getCommonFolder',
    async(data,{ dispatch })=>{
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${COMMON_FOLDER}/content`,getAuthHeader())
            return response.data;
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const getTrash = createAsyncThunk(
    'specialFolders/getTrash',
    async(data,{ dispatch })=>{
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/trash`,getAuthHeader())

            return response.data;
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)




export const deleteTrash = createAsyncThunk(
    'specialFolders/deleteTrash',
    async({userId, days},{ dispatch })=>{
        try{
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/folders/trash`,{
                headers: getAuthHeader().headers,
                data: {
                    user_id: userId,
                    days: days
                }
             });
             dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const getNotices = createAsyncThunk(
    'specialFolders/getNotices',
    async(data,{ dispatch })=>{
        try{

            let url = `${process.env.REACT_APP_BACKEND_URL}/api/folders/${NOTICE_FOLDER}/posts?offset=`  + (data.offset ?? 0)
            
            if(data.limit) {
                url += "&limit=" + data.limit

            }
            
            const response = await axios.get(url, getAuthHeader())

            return response.data;
        }
        catch(error) {
            // dispatch(globalNotification({
            //     status: "error",
            //     message: error.response.data.message
            // }))
        }
    }
)

export const getAlerts = createAsyncThunk(
    'specialFolders/getAlerts',
    async(data,{ dispatch })=>{
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${ALERT_FOLDER}/content`,getAuthHeader())

            return response.data;
        } catch(error){
            //dispatch(globalNotification(error.response.data.message))
            throw error
        }
    }
)


